import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { FC, useMemo } from "react";
import { Room } from "entitites";
import { capitalize } from "lodash";
import GallerySlider from "../../../components/GallerySlider/GallerySlider";
import { getDifferenceInDays } from "../../../infra/utils/date";
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import useHotelStore from "../../../store/hotel";


export interface RoomCardProps {
    room: Room;
    onSelectRoom: (room: Room) => void;
    isSelected: boolean;
    days?: number
}


const RoomCard: FC<RoomCardProps> = ({room, isSelected, onSelectRoom, days = 1}) => {


    const price = useMemo(() => {


        if (room?.payment_options?.payment_types[0]?.show_amount) {
            return (parseFloat(room?.payment_options?.payment_types[0]?.show_amount) / days).toFixed(2)
        }

        return (0).toFixed(2)
    }, [room])
    const subtitles = useMemo(() => {

        return room ? room.subtitle.split('\n').map(t => capitalize(t)).filter(t => t) : []
    }, [room])


    const galleryImgs = useMemo(() => {

        if (room) {
            if (room.images?.length) {
                return room.images
            }

            return [room.image as string]
        }


        return []
    }, [room]);

    return (
        <div
            className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden cursor-pointer ${isSelected
                ? "border-primary-500"
                : "border-neutral-100 dark:border-neutral-700"
            } hover:shadow-lg hover:scale-105`}
            onClick={() => onSelectRoom(room)}
        >
            <div className="nc-NcImage mb-8 rounded-md overflow-hidden" data-nc-id="NcImage">
                <GallerySlider
                    uniqueID={`RoomCard_${room.book_hash}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={galleryImgs}
                    isUseLinkImage={false}
                />
            </div>
            <div className="mb-8">
                <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                    {room.room_name}
                </h3>
                {room?.payment_options?.payment_types[0]?.show_amount &&
                    <h2 className="text-2xl xl:text-4xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
                        <span>€{price}</span>
                        <span className="text-lg ml-1 font-normal text-neutral-500">
                            /night
                        </span>
                    </h2>}
            </div>
            <nav className="space-y-4 mb-8">
                {subtitles.map((item, index) => (
                    <li className="flex items-center" key={index}>
                        <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                            {item.startsWith('No') ? <XMarkIcon className="w-5 h-5" aria-hidden="true"/> :
                                <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                            }
                        </span>
                        <span className="text-neutral-700 dark:text-neutral-300">
                            {item}
                        </span>
                    </li>
                ))}
            </nav>
            <div className="flex flex-col mt-auto">
            </div>
        </div>
    )
}

export default RoomCard
