import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";


export const NAVIGATION_DEMO: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/",
        name: "Home",
        isNew: true,
    },

    // {
    //   id: ncNanoId(),
    //   href: "/listing-stay",
    //   name: "Online Booking",
    // },
    {
        id: ncNanoId(),
        href: "/about",
        name: "About",
    },
    {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
    },
];
