import { Transition } from "@headlessui/react";
import { CarDataType, ExperiencesDataType } from "data/types";
import React, { FC, Fragment, useEffect } from "react";
import { useState } from "react";
import { Hotel } from "../../entitites";
import StayCardHotels from "../StayCard/StayCard_Hotels";

export interface AnyReactComponentProps {
  className?: string;
  listing?: Hotel;
  experiences?: ExperiencesDataType;
  car?: CarDataType;
  isSelected?: boolean;
  lat: number;
  lng: number;
}

const AnyReactComponent: FC<AnyReactComponentProps> = ({
  className = "",
  listing,
  car,
  experiences,
  isSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth <= 768);
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    const handleTouchMove = () => {
      if (isOpen && isMobile) setIsOpen(false);
    };

    if (isMobile) window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isOpen, isMobile]);
  const handleToggle = () => setIsOpen(true);

  return (
    <div
      className={`nc-AnyReactComponent relative ${className}`}
      onMouseEnter={!isMobile ? () => setIsOpen(true) : undefined}
      onMouseLeave={!isMobile ? () => setIsOpen(false) : undefined}
      onClick={isMobile ? handleToggle : undefined}
    >
      <span
        className={`flex px-2 py-1 rounded-lg  text-sm font-semibold items-center justify-center min-w-max shadow-lg transition-colors ${isSelected
            ? "bg-neutral-900 text-white dark:bg-white dark:text-neutral-900"
            : "bg-white dark:bg-neutral-900 hover:bg-neutral-900 hover:text-white dark:hover:bg-white dark:hover:text-neutral-900"
          }`}
      >
        {listing?.price || experiences?.price || car?.price}€
      </span>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
          {listing && (
            <StayCardHotels
              size="small"
              data={listing}
              className="shadow-2xl" />
          )}
          {/* {experiences && (
            <ExperiencesCard
              size="small"
              data={experiences}
              className="shadow-2xl bg-white dark:bg-neutral-900 pt-3 px-3 rounded-3xl"
            />
          )}
          {car && <CarCard size="small" data={car} className="shadow-2xl " />} */}
        </div>
      </Transition>
    </div>
  );
};

export default AnyReactComponent;
