import { create } from 'zustand'
import { Hotel, Review } from "entitites";

interface CheckoutState {
    item: any;
    isCheckoutProcessing: boolean;
    error: any;
    addItemToCheckout: (item: any) => void;
    reset: () => void;
}

const initialState = {
    item: null,
    isCheckoutProcessing: false,
    error: null,
}


const useCheckoutStore = create<CheckoutState>((set, get) => ({
    ...initialState,
    addItemToCheckout: (item) => {
        set({item})
    },
    reset: () => {
        set(initialState)
    },
}))

export default useCheckoutStore
