import { FC, useEffect, useMemo, useRef, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";
import useHotelsStore from "../../store/hotelsState";
import StayCardHHotels from "../../components/StayCardH/StayCardH_Hotels";
import Loader from "../../shared/Loader/Loader";
import useSearchStore from "../../store/searchState";
import useParamStore from "../../store/paramState";
import { useSearchParams } from "react-router-dom";
import PopUpHotelsList from "../../components/PopUp/PopUpHotelsList";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const hotels = useHotelsStore((state) => state.hotels);
  //search params
  const [, setSearchParams] = useSearchParams();

  const sectionRef = useRef<HTMLDivElement>(null);
  const scrollToSection = useSearchStore((state) => state.scrollToSection);
  const setScrollToSection = useSearchStore((state) => state.setScrollToSection);
  const loading = useHotelsStore((state) => state.loading);
  const loadMoreHotels = useHotelsStore((state) => state.loadMoreHotels);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [loadingMoreHotels, setLoadingMoreHotels] = useState(false);
  const hasMoreHotels = useHotelsStore((state) => state.hasMoreHotels);
  const dates = useSearchStore((state) => state.dates);
  const guests = useSearchStore((state) => state.guests);
  const location = useSearchStore((state) => state.location);
  const sort = useParamStore((state) => state.sort);
  const buttonSubmit = useSearchStore((state) => state.buttonSubmit);
  const popUpHotels = useHotelsStore((state) => state.popUpHotels);
  const setPopUpHotels = useHotelsStore((state) => state.setPopUpHotels);

  // скролл до секції готелів(лоадінгу поки готелі вантажаться) після пошуку
  useEffect(() => {
    if (scrollToSection) {
      sectionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollToSection(false); // Сбрасываем значение скролла после поиска
    }
    setSearchParams({ 
      location: JSON.stringify(location), 
      startDate: dates.startDate.toISOString(), 
      endDate: dates.endDate.toISOString(), 
      adults: guests.adults.toString(), 
      children: (guests.children + guests.infants).toString() 
    });
  }, [buttonSubmit]);

  // створення списку готелів
  const hotelList = useMemo(() => (
    hotels.map((item) => (
      <div
        key={item.id}
        onMouseEnter={() => setCurrentHoverID((_) => item.id)}
        onMouseLeave={() => setCurrentHoverID((_) => -1)}
      >
        <StayCardHHotels data={item} />
      </div>
    ))
  ), [hotels]);

  // спостерігання за елементом підвантаження готелів - та обробка підвантаження
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loadingMoreHotels) {
          setLoadingMoreHotels(true);
          loadMoreHotels({
            endDate: dates.endDate,
            startDate: dates.startDate,
            item_id: location.id,
            adults: guests.adults,
            children: new Array(guests.children + guests.infants).fill(8),
            sort: sort,
          }).finally(() => setLoadingMoreHotels(false));
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [loaderRef, loadMoreHotels, hotels, setLoadingMoreHotels, loadingMoreHotels]);

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          {popUpHotels && <PopUpHotelsList 
          onClose={() => setPopUpHotels(false)}
          href={window.location.href}
          />}
          <Heading2 />
          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>
            <div className="grid grid-cols-1 gap-8">
            {loading ? (
                <div ref={sectionRef}>
                  <Loader size="medium" />
                </div>
            ) : (
              <>
              {(hotels.length === 0 && !hasMoreHotels) ? (
                <div className="flex justify-center items-center text-3xl md:text-4xl font-semibold">
                  There are no hotels
                </div>
              ) : hotelList }
              {/* Элемент для наблюдения и подгрузки отелей */}
              {hasMoreHotels && (
                <div ref={loaderRef} className="flex justify-center items-center py-4">
                {/* Наблюдаемый элемент */}
                {loadingMoreHotels && <Loader size="medium" />}
                </div>
              )}
              </>
            )}
            </div>
        </div>

        {hotels.length > 0 && (
          <>
            {!showFullMapFixed && (
              <div
                className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                onClick={() => setShowFullMapFixed(true)}
              >
                <i className="text-lg las la-map"></i>
                <span>Show map</span>
              </div>
            )}

            {/* MAPPPPP */}
            <div
              className={`xl:flex-grow xl:static xl:block ${
                showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
              }`}
            >
              {showFullMapFixed && (
                <ButtonClose
                  onClick={() => setShowFullMapFixed(false)}
                  className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                />
              )}
              <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
                <MapContainer
                  key={hotels[0]?.id ?? 1} // This will force re-render when hotels list updates
                  currentHoverID={currentHoverID}
                  data={hotels}
                  DEMO_DATA={DEMO_STAYS}
                  listingType="stay"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SectionGridHasMap;
