import axios from "axios";

const getCountryByIp = async () => {
    try {
        const response = await axios.get("https://ipapi.co/json/")
        return response.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export default getCountryByIp
