import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
    className?: string;
    rightImg?: string;
    type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
    className = "lg:py-14",
    rightImg = rightImgPng,
    type = "type1",
}) => {
    return (
        <div
            className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
                } ${className}`}
            data-nc-id="SectionOurFeatures"
        >
            <div className="flex-grow">
                <NcImage src={rightImg} />
            </div>
            <div
                className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
                    }`}
            >
                <span className="uppercase text-sm text-gray-400 tracking-widest">
                    Benefits
                </span>
                <h2 className="font-semibold text-4xl mt-5">Why book with <br /> TON Hotels?</h2>

                <ul className="space-y-10 mt-16">
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                            Easy Hotel Search
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                            Book hotels to anywhere in the world
                        </span>
                    </li>
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                            Buy Now, Pay Later
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                            Spread the cost of your hotels with buy now, pay later options
                        </span>
                    </li>
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                            Over 40 Ways to Pay
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                            Pay by card, e-wallet, cryptocurrency and more
                        </span>
                    </li>
                    <li className="space-y-4">
                        <span className="block text-xl font-semibold">
                            Expert Customer Service
                        </span>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                            We have a dedicated Customer Service team on hand to help
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SectionOurFeatures;
