import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

interface PopUpProps {
  href?: string;
  onClose: () => void;
}

const PopUpHotelsList: React.FC<PopUpProps> = ({ 
  onClose,
  href
 }) => {
  const navigate = useNavigate();
  const newHref = href?.replace(process.env.REACT_APP_WEB_HOST || '', '');  
  const handleReturn = () => {
    onClose();
    navigate(newHref ?? '/listing-stay');
  };

  return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-neutral-800 rounded-lg p-8 shadow-xl w-full max-w-md text-center">
            <h2 className="text-white text-2xl font-semibold mb-4">Sorry, please try again</h2>
            <ButtonPrimary 
              type='submit' 
              onClick={handleReturn}>
              Close
            </ButtonPrimary>
          </div>
      </div>
  );
};

export default PopUpHotelsList;
