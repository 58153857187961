import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useSearchStore from "store/searchState";
import useHotelStore from "store/hotel";

export interface StayDatesRangeInputProps {
    isClickable?: boolean;
    className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
                                                               isClickable = true,
                                                               className = "flex-1",
                                                           }) => {

    const setStartDate = useSearchStore((state) => state.setStartDate);
    const setEndDate = useSearchStore((state) => state.setEndDate);
    const setDays = useSearchStore((state) => state.setDays);
    const {startDate, endDate} = useSearchStore((state) => state.dates);
    const loadingRooms = useHotelStore(state => state.loadingRooms)
    const [[startLocalDate, endLocalDate], setLocalDates] = useState([startDate, endDate]);


    useEffect(() => {
        if (!loadingRooms) {
            setLocalDates([startDate, endDate])
        }
    }, [loadingRooms]);

    const onChangeDate = (dates: [Date, Date]) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (dates[0] && dates[0] < today) dates[0] = today;
        setLocalDates(dates);
        setStartDate(dates[0]);
        if (!dates[1]) return; // if end date is not selected, return
        setEndDate(dates[1]);
        const days = () => {
            const diffTime = Math.abs(dates[1].getTime() - dates[0].getTime())
            return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 1)
        }
        setDays(days());
    };


    const renderInput = () => {
        return (
            <>
                <div className="text-neutral-300 dark:text-neutral-400">
                    <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7"/>
                </div>
                <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
            }) || "Add dates"}
              {endDate
                  ? " - " +
                  endDate?.toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                  })
                  : ""}
          </span>
                    <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
                </div>
            </>
        );
    };

    return (
        <Popover
            className={`StayDatesRangeInput z-10 relative flex ${className} ${!isClickable ? "pointer-events-none" : ""}`}>
            {({open}) => (
                <>
                    <Popover.Button
                        className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
                            open ? "shadow-lg" : ""
                        }`}
                        disabled={!isClickable} // Блокування кліків
                    >
                        {renderInput()}
                        {startDate && open && (
                            <ClearDataButton onClick={() => onChangeDate([startLocalDate, endLocalDate])}/>
                        )}
                    </Popover.Button>

                    {isClickable && (
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
                                <div
                                    className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                                    <DatePicker
                                        selected={startLocalDate}
                                        onChange={onChangeDate}
                                        startDate={startLocalDate}
                                        endDate={endLocalDate}
                                        selectsRange
                                        monthsShown={2}
                                        showPopperArrow={false}
                                        inline
                                        renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
                                        renderDayContents={(day, date) => <DatePickerCustomDay dayOfMonth={day}
                                        />}
                                    />
                                </div>
                            </Popover.Panel>
                        </Transition>
                    )}
                </>
            )}
        </Popover>

    );
};

export default StayDatesRangeInput;
