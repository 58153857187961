import axiosHotels from 'api'
import { CreateInvoiceParams } from "./types";
import CryptoJS from 'crypto-js';


const secretKey = process.env.SECRET_KEY as string
const generateHMAC = (body: string, timestamp: string): string => {
    const message = `${body}${timestamp}`;
    return CryptoJS.HmacSHA256(message, 'ssds').toString(CryptoJS.enc.Hex);
}

const paymentApi = {
    async createInvoice(params: CreateInvoiceParams): Promise<string | null> {
        try {
            const body = JSON.stringify(params);
            const timestamp = new Date().toISOString();
            const hmac = generateHMAC(body, timestamp);

            const response = await axiosHotels.post('/createInvoice', params, {
                headers: {
                    'X-Timestamp': timestamp,
                    'X-HMAC': hmac,
                },
            });

            return response.data.invoiceLink;
        } catch (e) {
            return null
        }
    }
}

export default paymentApi
