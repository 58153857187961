import { isDateInThePast } from "./date";

interface IQueryParams {
    startDate?: string | null;
    endDate?: string | null;
    location?: string | null;
    adults?: string | null;
    children?: string | null;

    [key: string]: any;
}

export const getQueryParams = (): IQueryParams => {
    const url = new URL(window.location.href); // Отримуємо поточний URL
    const params = new URLSearchParams(url.search); // Створюємо об'єкт для параметрів
    
    const allParams: IQueryParams = {};
    params.forEach((value, key) => {
        // if (key === 'startDate' || key === 'endDate') allParams[key] = convertToDate(value)

        allParams[key] = value;
    });

    return allParams;
};


function convertToDate(dateString: string): Date {

    const parts = dateString.split(' ');

    const isoString = `${parts[3]}-${getMonthNumber(parts[1])}-${parts[2]}`;
    const dateObject = new Date(isoString);

    function getMonthNumber(month: string): string {
        const months = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        };
        return months[month as keyof typeof months];
    }

    return dateObject;
}
