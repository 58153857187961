import React from "react";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useSearchStore from "store/searchState";
import useHotelStore from "store/hotel";
import useCheckoutStore from "store/checkout";
import { useNavigate } from "react-router-dom";
import { getQueryParams } from "../../../utils/getUrlQueryParams";
import { getDifferenceInDays } from "../../../infra/utils/date";

const MobileFooterSticky = () => {
    let {dates} = useSearchStore()
    const allParams = getQueryParams();
    
    if (allParams['startDate'] && allParams['endDate']) {
        dates = {
            startDate: new Date(allParams['startDate']),
            endDate: new Date(allParams['endDate']),
            days: getDifferenceInDays(new Date(allParams['startDate']), new Date(allParams['endDate']))
        }
    }
    const {hotel, selectedRoom} = useHotelStore()

    const {addItemToCheckout} = useCheckoutStore()
    const navigate = useNavigate()

    const reserveHotelRoom = () => {
        if (!selectedRoom) {
            return
        }

        addItemToCheckout({
            room: selectedRoom,
            hotel
        })
        navigate('/checkout')
    }

    return (
        <div
            className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
            <div className="container flex items-center justify-between">
                <div className="">
          <span className="block text-xl font-semibold">
            €{selectedRoom?.payment_options?.payment_types[0]?.show_amount}
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              total
            </span>
          </span>
                    <ModalSelectDate
                        renderChildren={({openModal}) => (
                            <span
                                onClick={openModal}
                                className="block text-sm underline font-medium"
                            >
                {converSelectedDateToString([dates.startDate, dates.endDate])}
              </span>
                        )}
                    />
                </div>

                {selectedRoom && <ButtonPrimary
                    sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                    onClick={reserveHotelRoom}
                >
                    Reserve
                </ButtonPrimary>}

            </div>
        </div>
    );
};

export default MobileFooterSticky;
