import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import useHotelStore from "store/hotel";
import { format } from 'date-fns'
import { getDifferenceInDays } from 'infra/utils/date'
import useSearchStore from "store/searchState";
import useCheckoutStore from "store/checkout";
import StarRating from "components/StarRating/StarRating";
import RoomCard from "./RoomCard";
import { Helmet } from "react-helmet-async";
import Loader from "../../../shared/Loader/Loader";
import { getQueryParams } from "../../../utils/getUrlQueryParams";
import { debounce } from "lodash";
import { useShallow } from 'zustand/react/shallow'
import { shallow } from "zustand/shallow";
import { isDateInThePast } from "../../../utils/date";
import toast from "react-hot-toast";

const StayDetailPageContainer: FC<{}> = () => {
    //

    const {
        hotel,
        loadingRooms,
        loadingRoomsError,
        hotelRooms,
        reviews,
        selectedRoom,
    } = useHotelStore(useShallow(state => ({
        hotel: state.hotel,
        loadingRooms: state.loadingRooms,
        loadingRoomsError: state.loadingRoomsError,
        hotelRooms: state.hotelRooms,
        reviews: state.reviews,
        selectedRoom: state.selectedRoom
    })))
    let {dates, guests, location} = useSearchStore(useShallow(state => ({
        dates: state.dates, guests: state.guests, location: state.location,
    })))
    const getHotelReviews = useHotelStore(state => state.getHotelReviews)
    const setRoom = useHotelStore(state => state.setRoom)
    const getRooms = useHotelStore(state => state.getRooms)
    const setStartDate = useSearchStore(state => state.setStartDate)
    const setEndDate = useSearchStore(state => state.setEndDate)
    const setDays = useSearchStore(state => state.setDays)
    const allParams = getQueryParams();
    const [params, setSearchParams] = useSearchParams()
    const searchStore = useSearchStore()
    const addItemToCheckout = useCheckoutStore(state => state.addItemToCheckout)


    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
    const chooseRoomRef = useRef<HTMLDivElement | null>(null)
    const [reserveError, setReserveError] = useState<string | null>(null)
    const [disabledBtn, setDisabledBtn] = useState(false)

    const REVIEWS_LIMIT = 10


    const navigate = useNavigate()


    const router = useNavigate();

    function closeModalAmenities() {
        setIsOpenModalAmenities(false);
    }

    function openModalAmenities() {
        setIsOpenModalAmenities(true);
    }

    const handleOpenModalImageGallery = () => {
        router(`?modal=PHOTO_TOUR_SCROLLABLE`);
    };

    const cashback = useMemo(() => {

        if (selectedRoom?.payment_options?.payment_types[0]?.show_amount) {
            return Math.round(parseFloat(selectedRoom?.payment_options?.payment_types[0].show_amount) * 0.05 * 100) / 100
        }

        return 0
    }, [selectedRoom])


    const getCheckoutTime = (timeStr?: string) => {
        if (timeStr) {
            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            const date = new Date();
            date.setHours(hours, minutes, seconds, 0);
            return format(date, 'HH:mm')
        }

        return null
    }

    const reserveHotelRoom = () => {
        if (!selectedRoom) {
            chooseRoomRef.current?.scrollIntoView({behavior: "smooth", block: "start"})
            setReserveError('Please select a room.')
            return
        }

        addItemToCheckout({
            room: selectedRoom,
            hotel
        })
        navigate('/checkout')
    }


    const handleGetRoomsDebounce = debounce(async () => {
        await getRooms()
        setDisabledBtn(false)
    }, 2000)

    useEffect(() => {
        if (hotelRooms && !selectedRoom) {
            setRoom(hotelRooms.rooms[0])
        }
    }, [hotelRooms]);

    useEffect(() => {
        if (selectedRoom) {
            setReserveError(null)
        }
    }, [selectedRoom]);

    useEffect(() => {

        if (allParams['startDate'] && allParams['endDate']) {
            const today = new Date()
            const current = new Date()
            current.setDate(today.getDate() + 1);
            isDateInThePast(new Date(allParams['startDate'])) ? setStartDate(new Date(new Date().toISOString())) : setStartDate(new Date(allParams['startDate']))
            isDateInThePast(new Date(allParams['endDate'])) ? setEndDate(new Date(current.toISOString())) : setEndDate(new Date(allParams['endDate']))
            setDays(getDifferenceInDays(dates.startDate, dates.endDate))
        }
    }, []);

    useEffect(() => {
        if (loadingRoomsError && allParams['startDate'] && allParams['endDate']) {
            toast.error('Invalid dates. Default dates applied.');
            getRooms()
        }
    }, [loadingRoomsError]);

    useEffect(() => {
        params.set('startDate', dates.startDate.toISOString())
        params.set('endDate', dates.endDate.toISOString())
        params.set('adults', guests.adults.toString())
        params.set('children', (guests.children + guests.infants).toString())
        setSearchParams(params);

    }, [searchStore.dates, searchStore.guests]);


    useEffect(() => {
        const unsubDateGuestUpdating = useSearchStore.subscribe((state) => [state.dates, state.guests], () => {
            setDisabledBtn(true)
            handleGetRoomsDebounce()
        }, {
            equalityFn: shallow
        })

        return unsubDateGuestUpdating
    }, []);

    const targetReviewRef = useRef<HTMLDivElement>(null);
    const targetMapRef = useRef<HTMLDivElement>(null);

    const scrollToElementWithOffset = (element: any) => {
        const offset = 100;
        if (element.current) {
            const elementPosition = element.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({top: offsetPosition, behavior: 'smooth'});
        }
    };

    const renderSection1 = () => {

        return (
            <div className="listingSection__wrap !space-y-6">
                {/* 1 */}
                <div className="flex justify-between items-center">
                    <Badge name={hotel?.kind}/>
                    {/*<span*/}
                    {/*    className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">*/}
                    {/*  <svg*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*      className="h-5 w-5"*/}
                    {/*      fill="none"*/}
                    {/*      viewBox="0 0 24 24"*/}
                    {/*      stroke="currentColor"*/}
                    {/*  >*/}
                    {/*    <path*/}
                    {/*        strokeLinecap="round"*/}
                    {/*        strokeLinejoin="round"*/}
                    {/*        strokeWidth={1.5}*/}
                    {/*        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"*/}
                    {/*    />*/}
                    {/*  </svg>*/}
                    {/*  <span className="hidden sm:block ml-2.5">Share</span>*/}
                    {/*</span>*/}
                </div>

                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                    {hotel?.title}
                </h2>

                {/* 3 */}
                <div className="flex items-center justify-between w-full">
                    <div className="flex items-center space-x-4">
                        {(hotel && hotel.star_rating !== 0) && (
                            <>
                                <StarRating point={hotel.star_rating}/>
                                <span>·</span>
                            </>
                        )}
                        <span>
                        <i className="las la-map-marker-alt"></i>
                        <span
                            className="ml-1 hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
                            onClick={() => {
                                scrollToElementWithOffset(targetMapRef)
                            }}>
                            {hotel?.address}
                        </span>
                        </span>
                    </div>

                    {/* Правий елемент: рейтинг з кількістю відгуків */}
                    <span
                        className="py-2 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
                        onClick={() => {
                            scrollToElementWithOffset(targetReviewRef)
                        }}
                    >
                        {hotel && (
                            <div>
                                <StartRating point={hotel.rating.rating} reviewCount={hotel.rating.votesCount}/>
                            </div>
                        )}
                    </span>

                </div>

                {/* 4 */}
                {/*      <div className="flex items-center">*/}
                {/*          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full"/>*/}
                {/*          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">*/}
                {/*  Hosted by{" "}*/}
                {/*              <span className="text-neutral-900 dark:text-neutral-200 font-medium">*/}
                {/*    Kevin Francis*/}
                {/*  </span>*/}
                {/*</span>*/}
                {/*      </div>*/}

                {/* 5 */}
                {/*<div className="w-full border-b border-neutral-100 dark:border-neutral-700"/>*/}

                {/* 6 */}
                {/*    <div*/}
                {/*        className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">*/}
                {/*        <div className="flex items-center space-x-3 ">*/}
                {/*            <i className=" las la-user text-2xl "></i>*/}
                {/*            <span className="">*/}
                {/*  6 <span className="hidden sm:inline-block">guests</span>*/}
                {/*</span>*/}
                {/*        </div>*/}
                {/*        <div className="flex items-center space-x-3">*/}
                {/*            <i className=" las la-bed text-2xl"></i>*/}
                {/*            <span className=" ">*/}
                {/*  6 <span className="hidden sm:inline-block">beds</span>*/}
                {/*</span>*/}
                {/*        </div>*/}
                {/*        <div className="flex items-center space-x-3">*/}
                {/*            <i className=" las la-bath text-2xl"></i>*/}
                {/*            <span className=" ">*/}
                {/*  3 <span className="hidden sm:inline-block">baths</span>*/}
                {/*</span>*/}
                {/*        </div>*/}
                {/*        <div className="flex items-center space-x-3">*/}
                {/*            <i className=" las la-door-open text-2xl"></i>*/}
                {/*            <span className=" ">*/}
                {/*  2 <span className="hidden sm:inline-block">bedrooms</span>*/}
                {/*</span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Stay information</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300">
                    {hotel && hotel.description.map((desc: any, descIndex: number) => {
                        return (
                            <div key={descIndex}>
                                {desc.paragraphs.map((p: any, pIndex: number) => (
                                    <span key={pIndex} dangerouslySetInnerHTML={{__html: p}}></span>
                                ))}
                                <br/>
                                <br/>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderSection3 = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Amenities </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        {` About the property's amenities and services`}
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                {hotel?.amenities && <div
                    className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {hotel.amenities.filter((_, i) => i < 12).map((item) => (
                        <div key={item.name} className="flex items-center space-x-3">
                            <i className={`text-3xl las la-check`}></i>
                            <span className=" ">{item.name}</span>
                        </div>
                    ))}
                </div>}

                {/* ----- */}
                <div className="w-14 border-b border-neutral-200"></div>
                {hotel && hotel.amenities?.length > 12 &&
                    <div>
                        <ButtonSecondary onClick={openModalAmenities}>
                            View more amenities
                        </ButtonSecondary>
                    </div>}
                {renderMotalAmenities()}
            </div>
        );
    };

    const renderMotalAmenities = () => {
        return (
            <Transition appear show={isOpenModalAmenities} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalAmenities}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-screen w-full max-w-4xl">
                                <div
                                    className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                                    <div
                                        className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3
                                            className="text-lg font-medium leading-6 text-gray-900"
                                            id="headlessui-dialog-title-70"
                                        >
                                            Amenities
                                        </h3>
                                        <span className="absolute left-3 top-3">
                                            <ButtonClose onClick={closeModalAmenities}/>
                                        </span>
                                    </div>
                                    <div
                                        className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                        {hotel && hotel.amenities.filter((_, i) => i < 1212).map((item) => (
                                            <div
                                                key={item.name}
                                                className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                                            >
                                                <i
                                                    className={`text-4xl text-neutral-6000 las la-check`}
                                                ></i>
                                                <span>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderSection6 = () => {
        return (
            <div ref={targetReviewRef} className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Reviews ({hotel?.rating.votesCount} reviews)</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* Content */}
                {/*<div className="space-y-5">*/}
                {/*    <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5"/>*/}
                {/*    <div className="relative">*/}
                {/*        <Input*/}
                {/*            fontClass=""*/}
                {/*            sizeClass="h-16 px-4 py-3"*/}
                {/*            rounded="rounded-3xl"*/}
                {/*            placeholder="Share your thoughts ..."*/}
                {/*        />*/}
                {/*        <ButtonCircle*/}
                {/*            className="absolute right-2 top-1/2 transform -translate-y-1/2"*/}
                {/*            size=" w-12 h-12 "*/}
                {/*        >*/}
                {/*            <ArrowRightIcon className="w-5 h-5"/>*/}
                {/*        </ButtonCircle>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* comment */}
                {reviews.items.length > 0 ?
                    (
                        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
                            {reviews.items.map(review => {
                                return <CommentListing
                                    key={hotel?.id + review.author}
                                    className="py-8"
                                    data={{
                                        key: hotel?.id + review.author,
                                        name: review.author,
                                        date: format(new Date(review.created_at), 'MMMM dd, yyyy'),
                                        comment: review.review_total,
                                        starPoint: +review.rating
                                    }}
                                />
                            })}
                            {hotel && reviews.items.length < hotel.rating.rating &&
                                <div className="pt-8">
                                    <ButtonSecondary loading={reviews.isLoadingReviews} onClick={() => getHotelReviews(
                                        {
                                            hotelId: hotel.id,
                                            limit: REVIEWS_LIMIT,
                                            offset: reviews.offset
                                        }
                                    )}>View more reviews</ButtonSecondary>
                                </div>}
                        </div>
                    ) : (
                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400"> No reviews yet.</span>
                    )
                }
            </div>
        );
    };

    const renderSection7 = () => {
        return (
            <div
                ref={targetMapRef}
                className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Location</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        {hotel?.address}
                    </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

                {/* MAP */}
                {hotel && <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                    <div className="rounded-xl overflow-hidden z-0">
                        <iframe
                            title="x"
                            width="100%"
                            height="100%"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${hotel?.latitude},${hotel?.longitude}`}
                        ></iframe>
                    </div>
                </div>}
            </div>
        );
    };

    const renderSection8 = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Things to know</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

                {/* CONTENT */}
                {hotel?.hotel_policy &&
                    (
                        <>
                            <div>
                                <h4 className="text-lg font-semibold">Hotel policy</h4>
                                <span dangerouslySetInnerHTML={{__html: hotel.hotel_policy}}
                                      className="block mt-3 text-neutral-500 dark:text-neutral-400">
                                </span>
                            </div>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>
                        </>
                    )
                }

                {/* CONTENT */}
                <div>
                    <h4 className="text-lg font-semibold">Check-in time</h4>
                    <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
                        <div
                            className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                            <span>Check-in</span>
                            <span>{getCheckoutTime(hotel?.checkinTime)}</span>
                        </div>
                        <div className="flex space-x-10 justify-between p-3">
                            <span>Check-out</span>
                            <span>{getCheckoutTime(hotel?.checkoutTime)}</span>
                        </div>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

                {/* CONTENT */}
                {/*<div>*/}
                {/*    <h4 className="text-lg font-semibold">Special Note</h4>*/}
                {/*    <div className="prose sm:prose">*/}
                {/*        <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">*/}
                {/*            <li>*/}
                {/*                Ban and I will work together to keep the landscape and*/}
                {/*                environment green and clean by not littering, not using*/}
                {/*                stimulants and respecting people around.*/}
                {/*            </li>*/}
                {/*            <li>Do not sing karaoke past 11:30</li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    };

    const renderSidebar = () => {
        return (
            <div className="listingSectionSidebar__wrap shadow-xl">
                {/* PRICE */}
                {loadingRooms ? (
                    <div className="flex">
                        <Loader size="small"/>
                    </div>
                ) : (
                    <div className="flex justify-between">
                        {selectedRoom?.payment_options?.payment_types[0]?.show_amount && (
                            <span className="text-3xl font-semibold">
                                    €{selectedRoom?.payment_options?.payment_types[0].show_amount}
                                <span
                                    className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"></span>
                                </span>
                        )}
                    </div>
                )}

                {/* FORM */}

                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <StayDatesRangeInput
                        className="flex-1 z-[11]"
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <GuestsInput
                        className="flex-1"
                    />
                </form>

                {/* SUM */}
                {loadingRooms ?
                    (
                        <div className="flex justify-center">
                            <Loader size="medium"/>
                        </div>
                    ) : selectedRoom?.payment_options?.payment_types[0]?.show_amount &&
                    <div className="flex flex-col space-y-4">
                        {dates.startDate && dates.endDate &&
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">

                                <span>  €{((parseFloat(selectedRoom?.payment_options?.payment_types[0].show_amount) / getDifferenceInDays(dates.startDate, dates.endDate))).toFixed(2)} x {getDifferenceInDays(dates.startDate, dates.endDate)} night</span>
                            </div>}
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            <span>Your cashback 5%</span>
                            <span>€{cashback}</span>
                        </div>
                        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                        <div className="flex justify-between font-semibold">
                            <span>Total</span>
                            <span>€{selectedRoom?.payment_options?.payment_types[0]?.show_amount}</span>
                        </div>
                    </div>}

                {/* SUBMIT */}
                {selectedRoom &&
                    <ButtonPrimary onClick={reserveHotelRoom}
                                   disabled={loadingRooms || disabledBtn}>Reserve</ButtonPrimary>}
            </div>
        );
    };


    const renderRoomSelectionSection = () => {
        return (
            <div ref={chooseRoomRef} className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Choose a room</h2>
                {
                    loadingRooms ?
                        (
                            <div className="flex justify-center items-center">
                                <Loader size="medium"/>
                            </div>
                        ) : hotelRooms?.rooms.length ? (
                            <>
                                {reserveError &&
                                    <span
                                        className='text-lg text-white font-medium block bg-blue-600 dark:bg-red-600 p-2 rounded-xl'>{reserveError}
                        </span>
                                }
                                <div className="grid sm:grid-cols-2 gap-5 xl:gap-8">
                                    {hotelRooms.rooms.map((room: any, index: number) => {
                                        return (
                                            <RoomCard key={room.book_hash} room={room}
                                                      isSelected={selectedRoom?.book_hash === room.book_hash}
                                                      onSelectRoom={setRoom}
                                                      days={getDifferenceInDays(dates.startDate, dates.endDate)}/>
                                        )
                                    })}
                                </div>
                            </>
                        ) : (
                            <h2 className="text-2xl font-semibold">No rooms available</h2>
                        )
                }
            </div>
        )
    }

    return (
        <div className="nc-ListingStayDetailPage">
            <Helmet>
                <title>{hotel?.title ?? 'Hotel'} | Hotelsforcrypto</title>
            </Helmet>
            {/*  HEADER */}
            <header className="rounded-md sm:rounded-xl">
                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                    <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                        onClick={handleOpenModalImageGallery}
                    >
                        {hotel && <img
                            className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                            src={hotel.pictures[0]}
                            alt=""
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        />}
                        <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                    </div>
                    {hotel && hotel.pictures.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                            key={index}
                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                            }`}
                        >
                            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                                <img
                                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                                    src={item || ""}
                                    alt=""
                                    sizes="400px"
                                />
                            </div>

                            {/* OVERLAY */}
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={handleOpenModalImageGallery}
                            />
                        </div>
                    ))}

                    <button
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                        onClick={handleOpenModalImageGallery}
                    >
                        <Squares2X2Icon className="w-5 h-5"/>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                            Show all photos
                        </span>
                    </button>
                </div>
            </header>

            {/* MAIN */}
            <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">


                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                    {renderSection1()}
                    {renderRoomSelectionSection()}
                    {renderSection2()}
                    {renderSection3()}
                    {/*{renderSection4()}*/}
                    {/*{renderSection5()}*/}
                    {renderSection6()}
                    {renderSection7()}
                    {renderSection8()}
                </div>

                {/* CONTENT */}

                {/* SIDEBAR */}
                <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                    <div className="sticky top-28">{renderSidebar()}</div>
                </div>


            </main>
        </div>
    );
};

export default function ListingStayDetailPage() {

    return (
        <DetailPagetLayout>
            <StayDetailPageContainer/>
        </DetailPagetLayout>
    );
}
