import Loader from "shared/Loader/Loader";
import React, { FC, useEffect, useState } from "react";
import getCountryByIp from "utils/getCountryByIp";
import { lowerCase } from "lodash";
import { PhoneInput } from "react-international-phone";


export interface PhoneInputProps {
    className?: string;
    onChange: (value: string) => void;
    isValid: boolean;
}

const InputPhone: FC<PhoneInputProps> = ({className, onChange, isValid}) => {
    const [phone, setPhone] = useState('');
    const [defaultCountry, setDefaultCountry] = useState<string | null>(null)

    useEffect(() => {
        fetchCountry()
    }, []);

    const fetchCountry = async () => {
        const data = await getCountryByIp()
        console.log(data, 'data')
        const countryCode = lowerCase(data?.country_code ?? 'us')
        setDefaultCountry(countryCode)
    }

    const handleChange = (phone: string) => {
        setPhone(phone)
        onChange && onChange(phone)
    }

    return (
        <>
            {defaultCountry ? <PhoneInput
                className={`dark:phone-dark ${className}`}
                defaultCountry={defaultCountry}
                value={phone}
                onChange={handleChange}
            /> : <Loader size="small"/>}
            {!isValid && <p className='text-md font-medium'>Phone is not valid</p>}
        </>
    )
}

export default InputPhone
