import { differenceInDays } from 'date-fns'

/**
 * Convert Date to "4 Oct 2023" format
 *
 * @param date - Date to format
 * @param short - Whether to use short month name "4/06/23" instead of "4 Jun 2023"
 */
export function formatDate(date: Date, short: boolean = false): string {
    const day = date.getDate()

    if (!short) {
        const month = date.toLocaleString('en-GB', {month: 'short'})
        const year = date.getFullYear()

        return `${day} ${month} ${year}`
    }

    const month = date.toLocaleString('en-GB', {month: 'numeric'})
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
}

export function getNextDay(currentDate = new Date(), daysToAdd = 1): Date {
    const nextDate = new Date(currentDate)
    nextDate.setDate(currentDate.getDate() + daysToAdd)
    return nextDate
}

export function splitDate(date: Date): string {
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}

export const getDifferenceInDays = (dateFrom: Date | string, dateTo: Date | string) => {
    const date1 = new Date(dateFrom);
    const date2 = new Date(dateTo);

    const diffDays = differenceInDays(date2, date1);

    return diffDays > 0 ? diffDays : 1
}

