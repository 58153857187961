import React from "react";
import { Helmet } from "react-helmet-async";

const TermsAndConditionsPage = () => {


    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>TonHotels Terms and Conditions</title>
            </Helmet>

            <div className="nc-SingleContent container space-y-10">
                <div
                    id="single-entry-content"
                    className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                >
                    <h2>TonHotels Terms and Conditions</h2>
                    <>
                        <p>
                            These Terms and Conditions (the “<strong>Terms</strong>”) govern the legally binding
                            relationship between us when you
                            use our Website and the Services that we provide. By using the Website or Services, you
                            acknowledge that you understand these Terms and agree to be legally bound by them.
                        </p>

                        <p>
                            In these Terms, references
                            to “<strong>us</strong>”, “<strong>we</strong>” or “<strong>our</strong>” shall
                            mean <strong>e-residence.com OÜ</strong>, a company registered in Estonia with its
                            registered address at Harju maakond, Tallinn, Kesklinna linnaosa,
                            Rotermanni tn 6, 10111. When we refer to "you", we mean any person that accesses or uses the
                            Services.
                        </p>

                        <p>
                            Bookings made via our MiniApp are subject to these Terms. One person making the Booking on
                            behalf of other travellers is taken to have the authority to agree to these Terms on behalf
                            of any other travellers.
                        </p>

                        <ol>
                            <li><strong>Definitions</strong></li>
                            <p> In these Terms:</p>
                            <ul>
                                <li><strong>Accommodation Provider</strong>: The provider of a hotel, home,
                                    accommodation, or other place of lodging.
                                </li>
                                <li><strong>Booking</strong>: The process of purchasing a selected hotel and the
                                    resulting outcome. A Booking is
                                    completed when we confirm receipt and acceptance of your payment for the Total
                                    Price.
                                </li>
                                <li><strong>Service Provider</strong>: The Accommodation Provider responsible for
                                    delivering the
                                    service(s) purchased
                                    as part of a Booking. Multiple Service Providers in a Booking are separate entities
                                    responsible for their respective services.
                                </li>
                                <li>
                                    <strong>Total Price</strong>: The final price displayed immediately prior to the
                                    purchase of your
                                    Booking.
                                </li>
                            </ul>
                            <li><strong>Acceptable Use</strong></li>
                            <p>2.1 In using the TonHotels, you agree and warrant that you:</p>
                            <ul>
                                <li> Will use TonHotels strictly for personal and non-commercial purposes only,
                                    acknowledging that use for any other purpose is prohibited.
                                </li>
                                <li> Will not gather, extract, reproduce, and/or display on any other website or online
                                    service, any material from the MiniApp, including hotel pricing and availability
                                    information, using robots, spiders, or other "screen scraping" software or systems.
                                </li>
                                <li> Will not use TonHotels for any unlawful purpose or any purpose not allowed under
                                    these Terms.
                                </li>
                                <li> Will not commit any act breaching privacy (including uploading private or personal
                                    information without consent) or any other legal rights of individuals.
                                </li>
                                <li>
                                    Will not use the TonHotels while impersonating another person.
                                </li>
                                <li>Will not use the TonHotels to defame or libel us, our employees, or other
                                    individuals.
                                </li>
                                <li> Will not transmit any viruses, Trojan horses, worms, or other items that may cause
                                    damage to our property or the property of others.
                                </li>
                                <li> Will not post or transmit to the TonHotels any unauthorized material, including but
                                    not limited to, material that is likely to cause annoyance, or is defamatory,
                                    racist, obscene, threatening, pornographic, or otherwise, or which is detrimental to
                                    or in violation of our systems or a third
                                    party’s systems or network security.
                                </li>
                                <li> Will not breach any third party’s rights (including intellectual property rights
                                    and confidentiality
                                    obligations) or infringe any laws in any jurisdiction using the Website.
                                </li>
                                <li>Will not attempt to gain unauthorized access to TonHotels, user accounts, or
                                    computer networks or systems connected to the TonHotels through hacking, password
                                    mining, or any other means.
                                </li>
                            </ul>
                            <p>
                                2.2 To make a Booking on the MiniApp, you must be at least 18 years of age (or the legal
                                minimum age in countries where it is greater than 18). By entering into this agreement,
                                you confirm that you possess the legal authority and necessary minimum age to use the
                                MiniApp in accordance with these Terms. TonHotels will not compensate you or anyone else
                                for expenses incurred as a result of minors
                                using the Services, including any Bookings placed by minors.
                            </p>
                            <p> 2.3 Unless otherwise indicated, we own or license from third parties all rights, title,
                                and interest
                                (including copyrights, designs, patents, trademarks, and other intellectual property
                                rights) contained on TonHotels and all material (including text, graphics, logos, audio,
                                and software)
                                made available (“Content”). Your use of TonHotels and access to any Content does not
                                grant or transfer any rights, title, or interest to you. However, we grant you a license
                                to access TonHotels and view the Content on the terms set out in these Terms. Any
                                reproduction or redistribution of the MiniApp or Content is prohibited and may result in
                                penalties.
                                Copying the Content to any other server, location, or support for publication,
                                reproduction, or distribution is expressly prohibited.</p>
                            <p>
                                2.4 We will determine whether there has been a breach of these Terms through your use of
                                TonHotels. If a breach has occurred, we may take action as we deem appropriate,
                                including denying you access, bringing legal proceedings against you, or disclosing such
                                information to law enforcement authorities.
                            </p>
                            <p> 2.5 TonHotels may contain links to other websites operated by third parties. These links
                                are provided for convenience and may not remain current or be maintained. We are not
                                liable if interference with or damage to your computer systems occurs in connection with
                                using the MiniApp or any linked website. You must take your own precautions to ensure
                                that whatever you select for your use from our MiniApp is free of viruses or other
                                malware that may interfere with or damage your
                                computer systems.</p>
                            <li><strong>Services</strong></li>
                            <p>3.1 This section defines the contractual relationship between you and us, outlining the
                                Services we provide in exchange for the Total Price, including:</p>
                            <ul>
                                <li>
                                    Displaying information and pricing of Hotels on the MiniApp.
                                </li>
                                <li>Providing MiniApp functions for making Bookings and purchasing hotel reservations
                                    for the Total Price.
                                </li>
                                <li>Facilitating the agreement between you and the relevant Service Provider.</li>
                                <li>
                                    Delivering your reservation confirmation resulting from your Booking.
                                </li>
                            </ul>
                            <p>
                                3.2 By completing and sending your online order on the MiniApp for your Booking, and
                                upon our acceptance of your payment, you agree to be bound by these Terms.
                            </p>
                            <p>
                                3.3 You represent and warrant that you have the legal capacity to enter into and be
                                bound by these Terms and have read and understood the terms and conditions of the
                                relevant Service Provider(s).
                            </p>
                            <p>3.4 You acknowledge the accuracy and currency of information provided during the Booking
                                process, accepting that we bear no liability for inaccuracies except where due to our
                                fault. It is your responsibility to ensure the correctness of guest details, travel
                                dates, and destinations provided during Booking.</p>
                            <p> 3.5 These Terms are separate from any terms, conditions, or agreements with Service
                                Providers or third parties responsible for fulfilling your Travel Product. Proceeding
                                with your Booking acknowledges these separate, independent legal relationships.</p>
                            <li><strong>Payments</strong></li>
                            <p> 4.1 Your price is guaranteed once the Total Price for your Booking is paid in full.
                                Taxes are subject to change and are finalized when your Travel Document is issued.
                                TonHotels is not subject to VAT, and no VAT invoice will be issued after completing your
                                Booking.</p>
                            <p>4.2 We accept payments via selected cryptocurrencies, credit and debit cards, bank wire
                                transfers, and other methods (collectively “Payment Method”).</p>
                            <p> 4.3 <strong>Cryptocurrency Payments</strong>: A Booking purchased with cryptocurrency is
                                ticketed once the transaction receives one confirmation on the relevant blockchain.
                                Miner fees, depending on network conditions and your selected fee, apply and are not
                                charged by TonHotels. Refer to our cryptocurrency refund policy in clause 8.6.</p>
                            <p> 4.4 <strong>Credit and Debit Cards</strong>: By agreeing to these Terms, you authorize
                                us to charge your designated credit or debit card for all fees related to our Services.
                                Additional costs associated with exchange rates, bank fees, credit card fees, or debit
                                card fees are not refundable by TonHotels.</p>
                            <p> 4.5 We reserve the right to delegate electronic payment collection management to
                                subsidiaries or third-party payment providers. Fees incurred for purchasing a Travel
                                Product are non-refundable, subject to applicable law.</p>
                            <p> 4.6 Our payment providers can securely store your payment details for later payment or
                                refunds of your Booking. By submitting your Booking form, you authorize us to facilitate
                                reservations and payment arrangements with Service Providers and third parties.
                            </p>
                            <p> 4.7 Personal information submitted for your Booking is protected by our secure SSL
                                payment gateway server using 256-bit security technology. This technology encrypts all
                                information and personal data passed through our Website.</p>
                            <li><strong>Changes, Cancellations, and Refunds</strong></li>
                            <p>
                                5.1 The applicable cancellation policy of the Travel Product is displayed prior to
                                completing the online Booking form.
                            </p>
                            <p>5.2 If your Booking is canceled or changed by the Service Provider or a related third
                                party, we will notify you as soon as possible. We are not liable for the consequences of
                                cancellations or changes made by these third parties.</p>
                            <p>5.3 All hotels are provided at a prepaid rate; therefore, refunds, regardless of the
                                payment method, are completely <strong>NON-REFUNDABLE.</strong></p>
                            <p><br/></p>
                            <p> For any questions or payment-related issues, please address them to: <br/>
                                <a href="https://t.me/TonHotels_support_bot" target="_blank">
                                    https://t.me/TonHotels_support_bot
                                </a> or <a href="mailto:team@e-residence.com">team@e-residence.com</a></p>
                            <p><br/></p>
                        </ol>

                    </>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsPage;
