import axiosHotels from 'api'

const botApi = {
    async sendSearchLocation(searchState: any, hotelsCount: number): Promise<any> {

        const response = (await axiosHotels.post('/send-search-location-message', {
            searchState,
            hotelsCount,
            affiliate_id: localStorage.getItem('affiliate_id'),
            affiliate_label: localStorage.getItem('affiliate_label'),
            web_app_url: process.env.REACT_APP_WEB_HOST
        })).data

        return response
    }
}

export default botApi
