import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import useSearchStore from "../../store/searchState";
import { GuestsObject } from "../HeroSearchForm/type";
export interface GuestsInputProps {
  defaultValue?: {adults: number; children: number; infants: number} | GuestsObject;
  onChange?: (data: any) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  className = "",
}) => {
  const guests = useSearchStore((state) => state.guests);
  const setGuests = useSearchStore((state) => state.setGuests);

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guests.adults}
        onChange={(e) => setGuests({...guests, adults: Number(e)})}
        max={10}
        min={1}
        label="Adults"
        desc="Ages 13 or above"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guests.children}
        onChange={(e) => setGuests({...guests, children: Number(e)})}
        max={4}
        label="Children"
        desc="Ages 2–12"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guests.infants}
        onChange={(e) => setGuests({...guests, infants: Number(e)})}
        max={4}
        label="Infants"
        desc="Ages 0–2"
      />
    </div>
  );
};

export default GuestsInput;
