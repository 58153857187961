import axios from 'axios'

const url = process.env.REACT_APP_API_HOST

// Create an axios instance with the base URL and headers
const axiosInstance = axios.create({
  baseURL: url,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

export default axiosInstance
