/* eslint-disable react-hooks/rules-of-hooks */
import axiosHotels from 'api'
import Filters from "entitites/Filters";
import { splitDate } from "infra/utils/date";
import { TripDetails } from '../../../entitites';
/**
 * Example of loading data
 * In real app data should be loaded from API in Domain layer using Transport
 */
export async function loadHotels(trip: TripDetails, filters: Filters): Promise<any> {
    const {
        item_id: city,
        adults,
        children,
        limit,
        offset,
        sort
    } = trip
    
    const startDate = splitDate(new Date(trip.startDate));
    const endDate = splitDate(new Date(trip.endDate));

    const response = (await axiosHotels.post('/hotels', {
        endDate,
        startDate,
        city,
        adults,
        children,
        limit,
        offset,
        sort
    }, {
        params: {
            stars: filters.stars.length ? filters.stars.join(',') : null,
            price_from: filters.priceFrom ? filters.priceFrom : null,
            price_to: filters.priceTo ? filters.priceTo : null
        }
    })).data
    
    return response
}

export async function loadHotelRates(trip: TripDetails): Promise<any> {
    const {
        item_id: city,
        hotel,
        adults,
        children,
    } = trip

    const startDate = splitDate(new Date(trip.startDate));
    const endDate = splitDate(new Date(trip.endDate));

    const response = (await axiosHotels.post('/hotel', {
        endDate,
        startDate,
        city,
        hotel,
        adults,
        children,
    })).data

    return response
}

export async function loadRoomsPhoto(hotel: string | undefined): Promise<any> {
    return (await axiosHotels.post('/photosroom', {hotel})).data
}
