import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import useSearchStore from "../../store/searchState";

export interface Heading2Props {
  heading?: string;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  subHeading,
}) => {
  const locationUI = useSearchStore((state) => state.locationUI);
  const dates = useSearchStore((state) => state.dates);
  const guests = useSearchStore((state) => state.guests);
  const monthStart = dates.startDate.toLocaleString("en-US", { month: "short" });
  const monthEnd = dates.endDate?.toLocaleString("en-US", { month: "short" });
  const guestsCount = guests.adults + guests.children + guests.infants;

  const isMap = useSearchStore((state) => state.isMap);
  const tabs = ["List", "Map"];
  const [tabActive, setTabActive] = useState<string>(isMap);
  const setIsMap = useSearchStore((state) => state.setIsMap);
  // check is it mobile
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize) };
  }, []);

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                setIsMap(tab);
              }}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${tab === tabActive
                ? ""
                : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
                } `}
              key={tab}
            >
              {tab === tabActive && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };


  return (
    <div className={`mb-12 lg:mb-16 ${className} flex justify-between items-center`}>
      <div className="flex flex-col">
        <h2 className="text-4xl font-semibold">{locationUI.title}</h2>
        {subHeading ? (
          subHeading
        ) : (
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
        {monthStart} {dates.startDate.getDate()} - {monthEnd === monthStart ? '' : monthEnd} {dates.endDate?.getDate() ?? ''}
        <span className="mx-2">·</span>{guestsCount} Guests
          </span>
        )}
      </div>
      {/* check is it mobile or not */}
      {!isMobile && (
        <div className="flex items-center ml-40">
          {renderTab()}
        </div>
      )}
    </div>
  );
};

export default Heading2;
