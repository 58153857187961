import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

interface PopUpProps {
  hotelId: string;
  onClose: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ 
  hotelId, 
  onClose
 }) => {
  const navigate = useNavigate();

  const handleReturn = () => {
    onClose();
    if (hotelId)return navigate(`/hotel/${hotelId}`);
    navigate('/listing-stay');
  };

  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-black">
    //   <div className="bg-neutral-800 rounded-lg p-6 shadow-lg w-full max-w-md text-center">

    //   </div>
    // </div>
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-neutral-800 rounded-lg p-8 shadow-xl w-full max-w-md text-center">
            <h2 className="text-white text-2xl font-semibold mb-4">Sorry, the room is no longer available</h2>
            <ButtonPrimary 
            type='submit' 
            onClick={handleReturn}>
              Return to Hotel
            </ButtonPrimary>
          </div>
      </div>
  );
};

export default PopUp;
