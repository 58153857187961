import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import useSearchStore from "../../store/searchState";
import { Hotel } from "../../entitites";
import StarRating from "../StarRating/StarRating";

export interface StayCardHProps {
  size?: "default" | "small";
  className?: string;
  data: Hotel;
}

const StayCardH: FC<StayCardHProps> = ({
  size = "default",

  className = "",
  data,
}) => {
  const {
    id,
    title,
    address,
    price,
    pictures,
    star_rating,
    rating,
  } = data;
  
  const dates = useSearchStore((state) => state.dates);
  const guests = useSearchStore((state) => state.guests);
  const href = `/hotel/${id}?startDate=${dates.startDate.toISOString()}&endDate=${dates.endDate.toISOString()}&adults=${guests.adults}&children=${guests.children}`

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={pictures}
          uniqueID={`StayCardH_${id}`}
          href={href}
        />
        <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" />
        {/* {price && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 guests
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 beds
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              3 baths
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-smoking-ban text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              No smoking
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 bedrooms
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-wifi text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Wifi
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {address}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <StarRating
            className="flex items-center space-x-1"
            point={star_rating}
          />
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {/* {renderTienIch()} */}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                from
              </span>
            )}
            {` `}
            {price + '\u{20AC}'}
            {` `}
            {size === "default" && (
              <>  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                    /
                  </span>
                  {` `}
                  {dates.days}
                  {` `}
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                      night{ dates.days > 1 ? 's' : '' }
                  </span></>
              )}
          </span>
          {!!rating.rating && (
              <StartRating reviewCount={rating.votesCount} point={rating.rating}/>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      <Link to={href} target="_blank" className="absolute inset-0"></Link>
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default StayCardH;
