import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import useSearchStore from "../../store/searchState";
import countriesObj from "../../infra/store/countries";
import { loadItems } from "../../infra/store/cities";
import CityState from "../../entitites/City_State";

interface Props {
    onClick?: () => void;
    onChange?: (value: string) => void;
    className?: string;
    defaultValue?: string;
    headingText?: string;
}

const LocationInput: FC<Props> = ({
                                      onChange = () => {
                                      },
                                      className = "",
                                      defaultValue = "United States",
                                      headingText = "Where to?",
                                  }) => {

    const location = useSearchStore((state) => state.location);

    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const [cities, setCities] = useState<any[]>([]); // Додаємо стан для міст
    const [isLoading, setIsLoading] = useState(false); // Стан для індикатора завантаження
    const locationError = useSearchStore((state) => state.locationError);
    const recentSearches = useSearchStore((state) => state.recentSearches);
    const setRecentSearches = useSearchStore((state) => state.setRecentSearches);
    const setLocationError = useSearchStore((state) => state.setLocationError);
    const setLocation = useSearchStore((state) => state.setLocation);
    const [value, setValue] = useState(location.title ?? "");
    const [hotels, setHotels] = useState<CityState[]>([])
    console.log("value", value);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    // Функція для перетворення об'єкта у масив міст
    const transformItems = (items: any[], type: 'city' | 'hotel') => {


        if (type === 'city') {
            return items.map((item: any) => ({
                id: item.id,
                title: item.title, // Перетворюємо "name" в "title"
                type: item.type,
                country: countriesObj[item.country_code]?.name ?? item.country_code,
            }))
        }

        if (type === 'hotel') {
            return items.map((item: any) => ({
                id: item.id,
                title: item.title,
                type: item.type,
                country: countriesObj[item?.region?.country_code]?.name ?? item.region_id,
                location: item?.region?.region_names['en'],
            }));
        }

        return []


    };

    // Функція для завантаження міст із API
    const fetchCities = async (query: string) => {
        if (!query) return;
        setIsLoading(true);
        try {
            const itemsResponse = await loadItems(query);
            const transformedCities = transformItems(itemsResponse.cities, 'city'); // Перетворюємо отримані дані
            const transformedHotels = transformItems(itemsResponse.hotels, 'hotel');
            console.log("transformedCities", transformedCities);
            setCities(transformedCities); // Встановлюємо перетворені дані в стан
            setHotels(transformedHotels)
        } catch (error) {
            console.error("Error fetching cities:", error);
            setCities([]); // Якщо помилка, встановлюємо порожній масив
            setHotels([])
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value && value.length >= 3) {
                fetchCities(value);
            }
        }, 400);
        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    const handleSelectLocation = (item: CityState) => {
        // DO NOT REMOVE SETTIMEOUT FUNC
        setTimeout(() => {
            setValue(item.title);
            onChange && onChange(item.title);
            setLocationError(false);
            // Тут ви можете передати id на сервер
            console.log("Selected City ID:", item.id);
            setLocation(item);
            setRecentSearches(item);
        }, 0);
    };

    const renderSearchValues = ({
                                    heading,
                                    items,
                                    isShowHotels = true
                                }: {
        heading?: string
        items: {
            cities: CityState[];
            hotels: CityState[];
        },
        isShowHotels?: boolean;
    }) => {
        if (isLoading) {
            return <p className="px-4 sm:px-8">Loading...</p>;
        }

        return (
            <>
                {heading && <p className="block font-semibold text-lg sm:text-xl mb-3">
                    {heading}
                </p>}

                <p className="block font-semibold text-base">
                    Locations
                </p>

                <div className="mt-3">
                    {items.cities.length ? items.cities.map((item) => {
                        return (
                            <div
                                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                                onClick={() => handleSelectLocation(item)}
                                key={item.id}
                            >
                                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400"/>
                                <span className="">{item.title}, {item.type}, {item.country}</span>
                            </div>
                        );
                    }) : <p className="px-4 sm:px-8">No locations found.</p>}
                </div>

                {
                    isShowHotels &&
                    <>
                        <p className="block font-semibold text-base">
                            Hotels
                        </p>

                        <div className="mt-3">
                            {items.hotels.length ? items.hotels.map((item) => {
                                return (
                                    <div
                                        className="py-2 mb-1 flex items-center space-x-3 text-sm"
                                        onClick={() => handleSelectLocation(item)}
                                        key={item.id}
                                    >
                                        <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400"/>
                                        <span className="">{item.title}, {item.location}, {item.country}</span>
                                    </div>
                                );
                            }) : <p className="px-4 sm:px-8">No hotels found.</p>}
                        </div>
                    </>
                }
            </>
        );
    };

    return (
        <div className={`${className}`} ref={containerRef}>
            <div className="p-5">
                <span className="block font-semibold text-xl sm:text-2xl">
                    {headingText}
                </span>
                <div className="relative mt-5">
                    <input
                        className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
                        value={value.length === 0 ? location.title : value}
                        onChange={(e) => {
                            setValue(e.currentTarget.value);
                            setLocationError(false);
                            setLocation({country: '', id: 0, title: '', type: ''});
                        }}
                        placeholder={"Search destinations"}
                        ref={inputRef}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}
                    />
                    {locationError && (
                        <span className="text-red-500 text-sm mt-1">
                            Please choose a destination to start searching.
                        </span>
                    )}
                    <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
                        <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400"/>
                    </span>
                </div>
                <div className="mt-7">
                    {value
                        ? renderSearchValues({
                            items: {
                                cities,
                                hotels
                            }
                        })
                        : renderSearchValues({
                            heading: "Popular destinations",
                            items: recentSearches,
                            isShowHotels: false
                        })}
                </div>
            </div>
        </div>
    );
};

export default LocationInput;
