import React, { FC, useEffect, useMemo, useState } from "react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useSearchStore from "store/searchState";
import { Resolver, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { string } from 'yup';
import { generateSchema, IField } from "../../infra/utils/validation";
import { Navigate } from "react-router-dom";
import orderApi from 'api/order'
import paymentApi from "api/payment"
import { formatDate, getDifferenceInDays } from "../../infra/utils/date";
import useCheckoutStore from "store/checkout";
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react'
import GuestsInput from "../ListingDetailPage/listing-stay-detail/GuestsInput";
import StayDatesRangeInput from "../ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import { Helmet } from "react-helmet-async";
import PopUp from "components/PopUp/PopUpRooms";
import isPhoneValid from "utils/isPhoneValid";
import InputPhone from "components/InputPhone/InputPhone";

export interface CheckOutPagePageMainProps {
    className?: string;
}


// Define a type for payment method objects
interface PaymentMethodOption {
    name: string;
    value: string;
}

// Default fallback payment method options
const DEFAULT_PAYMENT_METHOD_OPTIONS: PaymentMethodOption[] = [
    { name: 'Crypto', value: 'Crypto' }
];

// Retrieve payment method options from the environment or use default
const PAYMENT_METHOD_OPTIONS: PaymentMethodOption[] = (() => {
    try {
        const options = process.env.REACT_APP_PAYMENT_METHOD_OPTIONS;
        return options ? (JSON.parse(options) as PaymentMethodOption[]) : DEFAULT_PAYMENT_METHOD_OPTIONS;
    } catch (error) {
        console.error("Failed to parse PAYMENT_METHOD_OPTIONS from environment", error);
        return DEFAULT_PAYMENT_METHOD_OPTIONS;
    }
})();

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
    className = "",
}) => {

    const { dates, guests } = useSearchStore()
    const { item, reset: resetCheckout } = useCheckoutStore()
    const [isCheckoutProcessing, setIsCheckoutProcessing] = useState(false)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHOD_OPTIONS[0].value)
    const [showPopup, setShowPopup] = useState(false);

    const numberOfFields = guests.adults + guests.children + guests.infants;
    // Regular expression for first_name and last_name validation
    const nameRegex = new RegExp(
        "^[\\p{L}\\p{Script=Bengali}\\p{Script=Hebrew}\\p{Script=Devanagari}'\\-,.\\s]+$",
        'u'
    );


    // eslint-disable-next-line no-useless-escape
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    const cashback = useMemo(() => {
        console.log(item, 'item');
        const price = item?.room?.payment_options?.payment_types[0].show_amount
        if (price) {
            return Math.round(parseFloat(price) * 0.05 * 100) / 100
        }

        return 0
    }, [item?.room])

    const [phone, setPhone] = useState<string>('');
    const isValid = useMemo(() => {

        return isPhoneValid(phone)
    }, [phone])

    useEffect(() => {

        return () => {
            resetCheckout()
        }
    }, []);


    const fields: IField[] = [
        {
            name: 'first_name',
            label: 'First name',
            initialValue: '',
            isSecondScheme: true,
            type: string()
                .required('This field is required')
                .test('name-validation', 'Invalid characters in first name', value => {
                    return nameRegex.test(value);
                }),
        },
        {
            name: 'last_name',
            label: 'Last name',
            initialValue: '',
            isSecondScheme: true,
            type: string()
                .required('This field is required')
                .test('name-validation', 'Invalid characters in last name', value => {
                    return nameRegex.test(value);
                }),
        },
        {
            name: 'email',
            label: 'Email',
            initialValue: '',
            type: string().email('Invalid email format').required('Email is required').matches(emailRegex, 'Invalid email format')
        },
    ];


    const dynamicSchema = generateSchema(fields, 1, 1)


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(dynamicSchema) as unknown as Resolver<any>,
    })

    const createGuestObjects = (values: Record<string, any>, adultsNumber: number) => {
        const guests = [];

        for (let i = 0; i < numberOfFields; i++) {
            const number = 1
            if (i < adultsNumber) {
                guests.push({
                    first_name: values[`first_name${number}`],
                    last_name: values[`last_name${number}`],
                    phone: phone ?? '',
                    email: values[`email${number}`] ?? ''
                });
            } else {
                guests.push({
                    first_name: values[`first_name${number}`],
                    last_name: values[`last_name${number}`],
                    is_child: true,
                    age: 8,
                })
            }

        }

        return guests;
    };


    const onSubmit = async (data: any) => {

        setIsCheckoutProcessing(true)
        const guestsObjects = createGuestObjects(data, guests.adults)

        if (item?.room) {


            const show_amount = item.room.payment_options?.payment_types[0].show_amount ?? '0'
            const original_amount = item.room.payment_options?.payment_types[0].original_amount ?? '0'

            const orderData = await orderApi.createOrder({
                amount: parseFloat(show_amount),
                original_amount: parseFloat(original_amount),
                adults: guests.adults,
                startDate: dates.startDate.toLocaleDateString('en-CA'),
                endDate: dates.endDate.toLocaleDateString('en-CA'),
                hotel_id: item?.hotel?.id ?? 1,
                book_hash: item.room.book_hash,
                guests: guestsObjects,
                payment_method: selectedPaymentMethod,
                affiliate_id: localStorage.getItem('affiliate_id'),
                affiliate_label: localStorage.getItem('affiliate_label'),
                web_app_url: process.env.REACT_APP_WEB_HOST
            })

            if (orderData === null) {
                setIsCheckoutProcessing(false)
                toast.error(`Couldn't create invoice!`);
                return
            }
            if ('message' in orderData) {
                setIsCheckoutProcessing(false)
                setShowPopup(true);
                return;
            }

            const testUserIds = [828332192, 2200407858,];
            const user = {
                allows_write_to_pm: true,
                first_name: "danil",
                id: 5000489825,
                language_code: "ru",
                last_name: "Test",
                username:
                    "danilaprogrammer"
            }
            const invoiceLink = await paymentApi.createInvoice({
                user: user,
                title: item?.hotel?.title ?? 'Hotel reservation',
                description: `${formatDate(dates.startDate, true)} — ${formatDate(dates.endDate, true)}`,
                amount: testUserIds.includes(user.id) ? 0.02 : parseFloat(show_amount),
                external_id: orderData.externalId,
                room_name: item.room.room_name,
                book_hash: item.room.book_hash as string,
                currency: 'EUR',
                email: guestsObjects[0].email ?? '',
                images: item?.room?.images ?? [],
                payment_method: selectedPaymentMethod,
                hotelId: item?.hotel?.id ?? '',
                guests: guests,
                dates: dates,
                web_app_url: process.env.REACT_APP_WEB_HOST
            })

            setIsCheckoutProcessing(false)

            if (invoiceLink === null) {
                toast.error(`Couldn't create invoice!`);
                return
            }

            window.location.href = invoiceLink;

        }


    }

    const renderSidebar = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex flex-col sm:flex-row sm:items-center">
                    {item && <div className="flex-shrink-0 w-full sm:w-40">
                        <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            <img
                                alt=""
                                className="absolute inset-0 object-cover"
                                sizes="200px"
                                src={item.room?.image}
                            />
                        </div>
                    </div>}
                    <div className="py-5 sm:px-5 space-y-3">
                        <div>
                            {/*<span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">*/}
                            {/*  Hotel room in Tokyo, Jappan*/}
                            {/*</span>*/}
                            <span className="text-base font-medium mt-1 block">
                                {item?.hotel?.title}
                            </span>
                        </div>
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                        <span className="text-base font-medium mt-1 block">
                            {item?.room?.room_name}
                        </span>
                        {/*{hotel && <StartRating point={hotel.rating.rating} reviewCount={hotel.reviewsCount}/>}*/}
                    </div>
                </div>
                {/* FORM */}
                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <StayDatesRangeInput
                        className="flex-1 z-[11]"
                        isClickable={false}
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    <GuestsInput
                        className="flex-1"
                        isClickable={false}
                    />
                </form>
                <div className="flex flex-col space-y-4">
                    <h3 className="text-2xl font-semibold">Price detail</h3>
                    {dates.startDate && dates.endDate &&
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                            {item &&
                                <span>  €{((parseFloat(item?.room?.payment_options?.payment_types[0].show_amount) / getDifferenceInDays(dates.startDate, dates.endDate))).toFixed(2)} x {getDifferenceInDays(dates.startDate, dates.endDate)} night</span>}
                        </div>}
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Your cashback 5%</span>
                        <span>€{cashback}</span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>€{item?.room?.payment_options?.payment_types[0]?.show_amount}</span>
                    </div>
                </div>
            </div>
        );
    };


    const renderMain = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    Confirm and payment
                </h2>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <h3 className="text-2xl font-semibold">Guest information</h3>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

                    <div className="mt-6">
                        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
                            {Array.from({ length: 1 }).map((_, index) => {
                                const number = index + 1
                                return (
                                    <div key={index} className='flex flex-col gap-4'>
                                        <div className="space-y-1">
                                            <Label>First name </Label>
                                            <Input type="text"
                                                errorMessage={errors[`first_name${number}`]?.message}
                                                {...register(`first_name${number}`)}

                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Last name </Label>
                                            <Input type="text"
                                                errorMessage={errors[`last_name${number}`]?.message}
                                                {...register(`last_name${number}`)} />
                                        </div>


                                        <div className="space-y-1">
                                            <Label>Phone</Label>
                                            <InputPhone onChange={(value: string) => setPhone(value)}
                                                isValid={isValid} />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Email </Label>
                                            <Input type="email"
                                                errorMessage={errors[`email${number}`]?.message}
                                                {...register(`email${number}`)}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                            {/*{Array.from({length: guests.children + guests.infants}).map((_, index) => {*/}
                            {/*    const number = guests.adults + (index + 1)*/}
                            {/*    return (*/}
                            {/*        <div className='flex flex-col gap-4'>*/}
                            {/*            <span className='text-lg block mb-2'>Сhild №{number}</span>*/}
                            {/*            <div className="space-y-1">*/}
                            {/*                <Label>First name </Label>*/}
                            {/*                <Input type="text"*/}
                            {/*                       errorMessage={errors[`first_name${number}`]?.message}*/}
                            {/*                       {...register(`first_name${number}`)}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*            <div className="space-y-1">*/}
                            {/*                <Label>Last name </Label>*/}
                            {/*                <Input type="text"*/}
                            {/*                       errorMessage={errors[`last_name${number}`]?.message}*/}
                            {/*                       {...register(`last_name${number}`)}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*})}*/}

                            <div className="w-full">
                                <RadioGroup value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}
                                    name='payment'
                                >
                                    <RadioGroup.Label className="font-medium text-xl">Pay with:</RadioGroup.Label>
                                    <div className="flex w-2/3 gap-10 mt-5">
                                        {PAYMENT_METHOD_OPTIONS.map((paymentMethod) => (
                                            <RadioGroup.Option
                                                key={paymentMethod.name}
                                                value={paymentMethod.value}
                                                className={({ active, checked }) =>
                                                    `
                  ${checked ? 'bg-gray-200 text-black dark:bg-white dark:text-black' : 'bg-white text-black  dark:bg-black dark:text-white'}
                    relative flex cursor-pointer rounded-full px-6 py-3 shadow-md focus:outline-none`
                                                }
                                            >
                                                {({ active, checked }) => (
                                                    <>
                                                        <div className="flex w-full items-center justify-between">
                                                            <div className="flex items-center">
                                                                <div className="text-md font-medium">
                                                                    <RadioGroup.Label
                                                                        as="p"
                                                                    >
                                                                        {paymentMethod.name}
                                                                    </RadioGroup.Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>

                            <div className="pt-8">
                                <ButtonPrimary type='submit' loading={isCheckoutProcessing} disabled={!isValid}>Confirm
                                    and
                                    pay</ButtonPrimary>
                            </div>
                        </form>
                        {/*<div className="pt-8">*/}
                        {/*    <ButtonPrimary href={"/pay-done"}>Confirm and pay</ButtonPrimary>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    };

    if (!item) {
        return <Navigate to="/" />
    }

    return (
        <div className={`nc-CheckOutPagePageMain ${className}`}>
            <Helmet>
                <title>Hotel Booking | Hotelsforcrypto</title>
            </Helmet>
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                {showPopup && <PopUp hotelId={item?.hotel?.id} onClose={() => setShowPopup(false)} />}
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
                <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
            </main>
        </div>
    );
};

export default CheckOutPagePageMain;
