import { create } from 'zustand'
import CityState from '../entitites/City_State';
import { getQueryParams } from '../utils/getUrlQueryParams';
import { getDifferenceInDays } from '../infra/utils/date';
import { subscribeWithSelector } from "zustand/middleware";

interface SearchState {
    isMap: string;
    setIsMap: (isMap: string) => void;
    location: CityState;
    locationUI: CityState;
    locationError: boolean;
    dates: { startDate: Date; endDate: Date, days: number };
    guests: { adults: number; children: number, infants: number };
    recentSearches: {
        cities: CityState[],
        hotels: any[]
    },
    setLocationError: (error: boolean) => void;
    setLocation: (location: CityState) => void;
    setLocationUI: (locationUI: CityState) => void;
    setStartDate: (startDate: Date) => void;
    setEndDate: (endDate: Date) => void;
    setDays: (days: number) => void;
    setGuests: (guests: { adults: number; children: number; infants: number }) => void;
    setRecentSearches: (city: CityState) => void;
    scrollToSection: boolean;
    setScrollToSection: (value: boolean) => void;
    buttonSubmit: boolean;
    setButtonSubmit: (value: boolean) => void;
}

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const allParams = getQueryParams();

const useSearchStore = create<SearchState>()(subscribeWithSelector((set) => ({
    isMap: 'List',
    setIsMap: (isMap: string) => set({isMap}),
    location: allParams['location'] ? JSON.parse(allParams['location']) : {country: '', id: 0, title: '', type: ''},
    setLocation: (location: CityState) => set({location}),
    locationUI: allParams['location'] ? JSON.parse(allParams['location']) : {
        country: 'United Kingdom',
        id: 2114,
        title: 'London',
        type: 'City'
    },
    setLocationUI: (locationUI: CityState) => set({locationUI}),
    locationError: false,
    setLocationError: (error: boolean) => set({locationError: error}),
    dates: {
        startDate: allParams['startDate'] ? new Date(allParams['startDate']) : today,
        endDate: allParams['endDate'] ? new Date(allParams['endDate']) : tomorrow,
        days: allParams['startDate'] && allParams['endDate'] ? getDifferenceInDays(new Date(allParams['startDate']), new Date(allParams['endDate'])) : 1
    },
    setStartDate: (startDate: Date) => set((state) => {
        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + 1);
        return {dates: {...state.dates, startDate, endDate: newEndDate}};
    }),
    setEndDate: (endDate: Date) => set((state) => {
        const {startDate} = state.dates;
        if (startDate && endDate && endDate < startDate) return state
        if (startDate.getTime() === endDate?.getTime()) {
            const newEndDate = new Date(endDate);
            newEndDate.setDate(endDate.getDate() + 1);
            return {dates: {...state.dates, endDate: newEndDate}};
        }
        return {dates: {...state.dates, endDate}};
    }),
    setDays: (days: number) => set((state) => {
        return {dates: {...state.dates, days}}
    }),
    guests: {
        adults: allParams['adults'] ? parseInt(allParams['adults']) : 2,
        children: allParams['children'] ? parseInt(allParams['children']) : 0,
        infants: 0
    },
    setGuests: (guests) => set({guests}),
    recentSearches: {
        cities: [{country: 'United Kingdom', id: 2114, title: 'London', type: 'City'},
            {country: 'Spain', id: 2198, title: 'Madrid', type: 'City'},
            {country: 'Germany', id: 536, title: 'Berlin', type: 'City'},
            {country: 'Poland', id: 3765, title: 'Warsaw', type: 'City'},
            {country: 'Portugal', id: 2080, title: 'Lisbon', type: 'City'}],
        hotels: []
    },
    setRecentSearches: (item: CityState) => set((state) => {
        const isAlreadyInList = item.type === 'City' ? state.recentSearches.cities.some((search) => search.id === item.id) : state.recentSearches.hotels.some((search) => search.id === item.id);
        if (!isAlreadyInList) {
            const updatedRecentSearches = item.type === 'City' ? {
                ...state.recentSearches,
                cities: [item, ...state.recentSearches.cities],
            } : {
                ...state.recentSearches,
                hotels: [item, ...state.recentSearches.hotels],
            };
            if (updatedRecentSearches.cities.length > 5) updatedRecentSearches.cities.pop();
            if (updatedRecentSearches.hotels.length > 5) updatedRecentSearches.hotels.pop();
            return {...state, recentSearches: updatedRecentSearches};
        }
        return state;
    }),
    scrollToSection: false,
    setScrollToSection: (value) => set({scrollToSection: value}),
    buttonSubmit: false,
    setButtonSubmit: (value: boolean) => set({buttonSubmit: value}),
})));

export default useSearchStore;
