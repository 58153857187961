import React, { ReactNode, useEffect } from "react";
// import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import useHotelStore from "store/hotel";
import Loader from "shared/Loader/Loader";
import toast from "react-hot-toast";

const DetailPagetLayout = ({children}: { children: ReactNode }) => {
    const navigate = useNavigate();
    const thisPathname = useLocation().pathname;
    const [searchParams] = useSearchParams();
    const modal = searchParams?.get("modal");
    let {id} = useParams();

    const {hotel, isLoadingHotel, getHotel, error, reset} = useHotelStore()

    useEffect(() => {
        if (id) {
            getHotel({
                hotelId: id
            })
        }

        return () => {
            reset()
        }
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(error.status === 404 ? `Hotel not found.` : 'Something went wrong.');
            if (error.status === 404) {
                setTimeout(() => {
                    navigate('/page404')
                }, 2000)
            } else {
                setTimeout(() => {
                    navigate('/')
                }, 2000)
            }
        }
    }, [error]);

    const handleCloseModalImageGallery = () => {
        let params = new URLSearchParams(document.location.search);
        params.delete("modal");
        navigate(`?${params.toString()}`);
    };

    const getImageGalleryListing = () => {
        if (thisPathname?.includes("/hotel")) {
            if (hotel?.pictures.length) {
                return hotel.pictures.map((image, index) => ({
                    id: index,
                    url: image
                }))
            }

            return []
        }
        if (thisPathname?.includes("/listing-car-detail")) {
            return listingCarImageGallery;
        }
        if (thisPathname?.includes("/listing-experiences-detail")) {
            return listingExperienceImageGallery;
        }

        return [];
    };

    return (
        <div className="ListingDetailPage">

            <ListingImageGallery
                isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
                onClose={handleCloseModalImageGallery}
                images={getImageGalleryListing()}
            />
            

            <div className="container ListingDetailPage__content">{isLoadingHotel || error ?
                <Loader/> : children}
            </div>

            {/* OTHER SECTION */}
            <div className="container py-12 lg:py-12">
                {/* <div className="relative py-16"> *
                    <BackgroundSection/>
                    <SectionSliderNewCategories
                        heading="Explore by types of stays"
                        subHeading="Explore houses based on 10 types of stays"
                        categoryCardType="card5"
                        itemPerRow={5}
                        sliderStyle="style2"
                        uniqueClassName="ListingDetailPage"
                    />
                </div>
                 <SectionSubscribe2 className="pt-24 lg:pt-32"/> */}
            </div>

            {/* STICKY FOOTER MOBILE */}
            {!isLoadingHotel && hotel && <MobileFooterSticky/>}

        </div>
    );
};

export default DetailPagetLayout;
