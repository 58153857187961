import { create } from 'zustand'

const typeOfSort = [
  {
    name: "Lower price first",
    sort: "priceAsc",
  },
  {
    name: "Higher price first",
    sort: "priceDesc",
  },
  {
    name: "Property stars (low to high)",
    sort: "starRatingAsc",
  },
  {
    name: "Property stars (high to low)",
    sort: "starRatingDesc",
  },
  {
    name: "Property rating (low to high)",
    sort: "ratingAsc",
  },
  {
    name: "Property rating (high to low)",
    sort: "ratingDesc",
  },
  {
    name: "Recommended",
    sort: null,
  },
];


interface ParamState {
  sortTypes: { name: string, sort: string | null }[];
  sort: string | null;
  setSort: (name: string) => void;
}

const useParamStore = create<ParamState>((set, get) => ({
  sortTypes: typeOfSort,
  sort: null,
  setSort: (name: string) => {
    const sortType = typeOfSort.find(type => type.name === name);
    if (sortType) {
      set({ sort: sortType.sort });
    }
  }
}));

export default useParamStore;
