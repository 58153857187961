import { FC, useEffect, useMemo, useRef, useState } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import useHotelsStore from "../../store/hotelsState";
import StayCardHotels from "../../components/StayCard/StayCard_Hotels";
import useSearchStore from "../../store/searchState";
import Loader from "../../shared/Loader/Loader";
import useParamStore from "../../store/paramState";
import { useSearchParams } from "react-router-dom";
import PopUpHotelsList from "../../components/PopUp/PopUpHotelsList";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const hotels = useHotelsStore((state) => state.hotels);
  const sectionRef = useRef<HTMLDivElement>(null);
  const scrollToSection = useSearchStore((state) => state.scrollToSection);
  const setScrollToSection = useSearchStore((state) => state.setScrollToSection);
  const loading = useHotelsStore((state) => state.loading);
  const loadMoreHotels = useHotelsStore((state) => state.loadMoreHotels);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [loadingMoreHotels, setLoadingMoreHotels] = useState(false);
  const hasMoreHotels = useHotelsStore((state) => state.hasMoreHotels);
  const dates = useSearchStore((state) => state.dates);
  const guests = useSearchStore((state) => state.guests);
  const location = useSearchStore((state) => state.location);
  const sort = useParamStore((state) => state.sort);
  const popUpHotels = useHotelsStore((state) => state.popUpHotels);
  const setPopUpHotels = useHotelsStore((state) => state.setPopUpHotels);

  const buttonSubmit = useSearchStore((state) => state.buttonSubmit);

  //search params
  const [, setSearchParams] = useSearchParams();
  
  // скролл до секції готелів(лоадінгу поки готелі вантажаться) після пошуку
  useEffect(() => {
    if (scrollToSection) {
      sectionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollToSection(false); // Сбрасываем значение скролла после поиска
    }
    setSearchParams({ 
      location: JSON.stringify(location), 
      startDate: dates.startDate.toISOString(), 
      endDate: dates.endDate.toISOString(), 
      adults: guests.adults.toString(), 
      children: (guests.children + guests.infants).toString() 
    });
  }, [buttonSubmit]);

  // створення списку готелів
  const hotelList = useMemo(() => (
    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {hotels.map((stay) => (
        <StayCardHotels key={stay.id} data={stay} />
      ))}
    </div>
  ), [hotels]);

  // спостерігання за елементом підвантаження готелів - та обробка підвантаження
  useEffect(() => {

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loadingMoreHotels) {
          setLoadingMoreHotels(true);
          loadMoreHotels({
            endDate: dates.endDate,
            startDate: dates.startDate,
            item_id: location.id,
            adults: guests.adults,
            children: new Array(guests.children + guests.infants).fill(8),
            sort: sort,
          }).finally(() => setLoadingMoreHotels(false));
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [loaderRef, hotels, loadingMoreHotels]);

  // заміна компоненту списка готелів на лоадер(лише при першому завантаженні готелів( по кліку на кнопку пошуку або при виборі філтрів))
  if (loading) {
    return (
      <div
        className={`nc-SectionGridFilterCard ${className}`}
        data-nc-id="SectionGridFilterCard"
      >
        <Heading2 />
        <div 
        ref={sectionRef} 
        className="mb-8 lg:mb-11">
          <TabFilters />
        </div>
        <Loader size="medium" />
      </div>
    );
  }
  
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {popUpHotels && <PopUpHotelsList 
      onClose={() => setPopUpHotels(false)}
      href={window.location.href}
      />}

      <Heading2 />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>
      {(hotels.length === 0 && !hasMoreHotels) ? (
        <div className="flex justify-center items-center text-3xl md:text-4xl font-semibold">
          There are no hotels
        </div>
      ) : hotelList }
      
      {/* Элемент для наблюдения и подгрузки отелей */}
      {hasMoreHotels && (
        <div ref={loaderRef} className="flex justify-center items-center py-4">
          {/* Наблюдаемый элемент */}
          {loadingMoreHotels && <Loader size="medium" />}
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
