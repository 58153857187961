import { object, string } from "yup";

export interface IField {
    name: string,
    label: string,
    initialValue: any,
    type: any
    isSecondScheme?: boolean
}

export const generateSchema = (fields: IField[], baseSchemeNumber: number, total: number) => {


    const schemaArr = []
    for (let i = 0; i < total; i++) {
        for (let j = 0; j < fields.length; j++) {
            if (i < baseSchemeNumber) {
                schemaArr.push([`${fields[j].name}${i + 1}`, fields[j].type])
            } else if (fields[j].isSecondScheme) {
                schemaArr.push([`${fields[j].name}${i + 1}`, fields[j].type])
            }
        }
    }

    console.log(schemaArr, 'arr')
    const SchemaObject = Object.fromEntries(schemaArr)

    console.log(SchemaObject, 'schema')
    const baseSchema = object().shape(SchemaObject);

    return baseSchema
}
