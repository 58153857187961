import React, { FC } from 'react';
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
// import { StarIcon as OutlineStarIcon } from "@heroicons/react/24/outline"; // Використаємо для порожніх зірок


export interface StarRatingProps {

  className?: string;

  reviewCount?: number;

  point?: number; // Add this line

}

const StarRating: FC<StarRatingProps> = ({
  className = "",
  point = 0,
}) => {
  const fullStars = Math.floor(point);  // Кількість повних зірок
  // const hasHalfStar = point % 1 !== 0;  // Перевірка на половину зірки
  // const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);  // Кількість порожніх зірок

  return (
    <div className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}>
      {/* Повні зірки */}
      {fullStars > 0 ? (
        [...Array(fullStars)].map((_, index) => (
          <SolidStarIcon key={index} className="w-[18px] h-[18px] text-orange-500" />
        ))
      ) : (
        // Порожній div для збереження простору (фіксована висота і ширина)
        <div className="w-[18px] h-[18px]"></div>
      )}
      
      {/* Половина зірки (якщо є)
      {hasHalfStar && (
        <SolidStarIcon className="w-[18px] h-[18px] text-orange-500 opacity-50" />
      )}
       */}
      {/* Порожні зірки
      {[...Array(emptyStars)].map((_, index) => (
        <OutlineStarIcon key={index} className="w-[18px] h-[18px]  text-gray-500" />
      ))} */}
    </div>
  );
};

export default StarRating;
