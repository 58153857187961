import {
  AdvancedMarker,
  Map,
} from "@vis.gl/react-google-maps";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import { FC } from "react";
import { Hotel } from "../entitites";

interface MapContainerProps {
  currentHoverID: string | number;
  data?: Hotel[];
  DEMO_DATA: CarDataType[] | ExperiencesDataType[] | StayDataType[];
  listingType: "car" | "experiences" | "stay";
}

const MapContainer: FC<MapContainerProps> = ({
  currentHoverID = -1,
  data,
  DEMO_DATA,
}) => {
  return (
    <>
      {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
      <Map
        style={{
          width: "100%",
          height: "100%",
        }}
        defaultZoom={12}
        defaultCenter={data ? { lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude) } : DEMO_DATA[0].map}
        gestureHandling={"greedy"}
        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
      >
        {data?.map((item) => {
          const position = { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) };
          return (
            <AdvancedMarker
              key={item.id}
              position={position}
              clickable
              onClick={() => console.log("clicked")}
            >
              <AnyReactComponent
                isSelected={currentHoverID === item.id}
                key={item.id}
                lat={position.lat}
                lng={position.lng}
                car={undefined}
                experiences={undefined}
                listing={item}
              />
            </AdvancedMarker>
          );
        })}
      </Map>
    </>
  );
};

export default MapContainer;
