import DatePicker from "react-datepicker";
import React, { FC, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useSearchStore from "../../store/searchState";

export interface StayDatesRangeInputProps {
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
}) => {
  const setStartDate = useSearchStore((state) => state.setStartDate);
  const setEndDate = useSearchStore((state) => state.setEndDate);
  const setDays = useSearchStore((state) => state.setDays);
  const {startDate, endDate} = useSearchStore((state) => state.dates);
  const [[startLocalDate, endLocalDate], setLocalDates] = useState([startDate, endDate]);


  const onChangeDate = (dates: [Date, Date]) => {
    // регулювання дати, щоб не можна було вирати ранішу за сьогодні
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (dates[0] && dates[0] < today) dates[0] = today;
    setLocalDates(dates);
    setStartDate(dates[0]);
    if (!dates[1]) return; // if end date is not selected, return
    setEndDate(dates[1]);
    const days = () => {
        const diffTime = Math.abs(dates[1].getTime() - dates[0].getTime())
        return Math.max(Math.ceil(diffTime / (1000 * 60 * 60 * 24)), 1)
    }
    setDays(days());
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startLocalDate}
          onChange={onChangeDate}
          startDate={startLocalDate}
          endDate={endLocalDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
