import React from "react";
import { Helmet } from "react-helmet-async";

const PrivacyPolicyPage = () => {


    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>TonHotels Privacy Policy</title>
            </Helmet>

            <div className="nc-SingleContent container space-y-10">
                <div
                    id="single-entry-content"
                    className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                >
                    <h2>TonHotels Privacy Policy</h2>
                    <>
                        <p>
                            In these Terms, references to "<strong>us</strong>", "<strong>we</strong>", or
                            "<strong>our</strong>" shall
                            mean <strong>e-residence.com OÜ</strong>, a company registered in Estonia with its
                            registered address at Harju maakond, Tallinn, Kesklinna linnaosa, Rotermanni tn 6, 10111.
                            When we refer to "you", we mean any person that accesses or uses the Services.
                        </p>

                        <p><strong>e-residence.com OÜ</strong>, a company incorporated in Estonia (hereinafter referred
                            to as "<strong>TonHotels</strong>", "<strong>we</strong>" or "<strong>us</strong>")
                            recognizes the importance of privacy. In this privacy policy, we describe how we collect,
                            use, and disclose information that we obtain about visitors to our
                            MiniApp and the services available through our website.
                        </p>

                        <ol>
                            <li><strong>Information We Collect</strong></li>
                            <p> We collect personal information directly from you, from third parties, and automatically
                                through your use of our website. We may combine information automatically collected with
                                other information that we have collected about you. Where applicable, we may also
                                collect "Anonymous Data" from you, which means data, including aggregated and
                                de-identified data, that is not associated with or linked to your personal information.
                                Anonymous Data does not, by itself,
                                permit the identification of individual persons. Personal information means any
                                information that can be used to identify you.</p>
                            <p>We are the Data Controller for data collected through our website.</p>
                            <ul>
                                <li>Information we collect from you (or someone acting for you);</li>
                                <li>Contact information, including your name, address, email address, telephone number,
                                    and other contact details;
                                </li>
                                <li>Billing or payment information, such as your credit card number, cardholder name,
                                    expiration date, authentication code, and billing address;
                                </li>
                                <li>Details of the products and services we have provided to you or that you have
                                    enquired about, including any additional information necessary to deliver those
                                    products and services and respond to your enquiries;
                                </li>
                                <li>Information you provide to us through customer surveys or feedback;</li>
                                <li>
                                    Your device ID, device type, geo-location information, computer and connection
                                    information, statistics on page views, traffic to and from the Website, MiniApp, ad
                                    data, IP address, and standard web log information;
                                </li>
                                <li>Any additional information relating to you that you provide to us through your use
                                    of our website, in person, or through other websites or accounts from which you
                                    permit us to collect information;
                                </li>
                                <li>
                                    Any other personal information that may be required to facilitate your dealings with
                                    us.
                                </li>
                                <li>
                                    Information about you we collect from third parties.
                                </li>
                                <li>
                                    Information we collect automatically. We automatically collect information through
                                    your use of our website using cookies and other technologies. This information
                                    includes your:
                                </li>
                                <li>
                                    Domain name, operating system, settings, and system configurations;
                                </li>
                                <li>IP address;</li>
                                <li>The webpages you access within our website;</li>
                                <li>The website to which you go after leaving our website;</li>
                                <li>The dates and times you access our website;</li>
                                <li>Web log personal information;</li>
                                <li>Telegram ID.</li>
                                <li>Public information we may record. We may collect data from activity that is publicly
                                    visible and/or accessible on blockchains. This may include blockchain addresses and
                                    information regarding purchases, sales, or transfers of NFTs, which may then be
                                    associated with other data you have provided to us.
                                </li>
                            </ul>
                            <li><strong>How We Use Your Information</strong></li>
                            <p>We use your information for the following purposes:</p>
                            <ul>
                                <li> Providing our services. To provide accommodation on the MiniApp, manage your
                                    account, communicate with you (including via email and Telegram) about your use of
                                    our services, respond to your inquiries, and for similar customer service purposes.
                                </li>
                                <li>
                                    Marketing. Where you consent, we will provide you with news, special offers,
                                    promotions, and information about products we think may interest you; and for other
                                    marketing, advertising, and promotional purposes provided that you have not opted
                                    out of receiving such communications.
                                </li>
                                <li>
                                    Analyzing Use of Our Services. To better understand how users access and use our
                                    website and services on an aggregated basis; to respond to user desires and
                                    preferences; to improve our website navigation and its service; and for other
                                    research and analytical purposes. In addition, we may create Anonymous Data records
                                    derived from personal information provided for the aforesaid purposes. We reserve
                                    the right to use Anonymous Data for any lawful purpose and to disclose Anonymous
                                    Data to third parties lawfully.
                                </li>
                                <li>
                                    To Protect Rights and Interests. To protect our rights and interests, as well as the
                                    rights and interests of our customers, users of our website or services, and any
                                    other person.
                                </li>
                            </ul>
                            <li><strong>Marketing</strong></li>
                            <p>TonHotels would like to send you information about our products and services that we
                                think you might like. If you have agreed to receive marketing communications, you may
                                always opt out at a later date. You have the right at any time to stop us from
                                contacting you for marketing purposes. If you no longer wish to be contacted for
                                marketing purposes, please click the ‘Unsubscribe’ button contained in the footer of
                                each of our emails or reply to us with this email.</p>
                            <li><strong>How We Store Your Personal Information</strong></li>
                            <p>We undertake several physical, administrative, personnel, and technical measures to
                                protect your personal information and prevent it from misuse, interference, and loss, as
                                well as unauthorized access, modification, or disclosure. Our data security measures
                                include, but are not limited to: Secure Sockets Layer (SSL) encryption technology,
                                pseudonymization, internal data access restrictions, and strict physical access controls
                                to buildings & files. TonHotels will keep your personal information for the duration
                                that your account is active, and for the requisite period in accordance with applicable
                                law following the deactivation of your account. Once this time period has expired, we
                                will delete your personal information.</p>
                            <li><strong>Security</strong></li>
                            <p>We take reasonable steps to protect your personal information from misuse, loss,
                                unauthorized access, modification, or disclosure. For example, we take steps to destroy
                                or permanently de-identify personal information if we no longer need it for any purpose.
                                Please be aware that despite our efforts, no personal information security measures can
                                guarantee 100% security.</p>
                            <li><strong>How We Disclose Your Information</strong></li>
                            <p>
                                We may disclose your information, including personal information, with the following
                                entities:
                            </p>
                            <ul>
                                <li>Our Group of Companies and Employees. We disclose your required information to our
                                    employees, subsidiary, related, and/or affiliated companies to perform the necessary
                                    duties to provide you with our services.
                                </li>
                                <li>Service Providers. We disclose your information to our vendors, service providers,
                                    or others who perform functions on our behalf. All service providers are required to
                                    keep your personal data safe and process it pursuant to a data processing agreement.
                                    If the service provider is located outside of the UK or the EU, we put in place
                                    measures to ensure that your information has the same level of protection.
                                </li>
                            </ul>
                            <p>
                                We may also disclose your information, including personal information, in the following
                                ways:
                            </p>
                            <ul>
                                <li>Business Transfers. We may disclose your information to another entity if we are
                                    acquired by or merged with another company, if we sell or transfer a business unit
                                    or assets to another company, as part of a bankruptcy proceeding, or as part of a
                                    similar business transfer.
                                </li>
                                <li>Protecting Rights and Interests. We may disclose your information where we believe
                                    it is necessary to investigate, prevent, or take action regarding illegal
                                    activities, suspected fraud, situations involving potential threats to the safety of
                                    any person, violations of our Terms and Conditions or this privacy policy, or as
                                    evidence in litigation in which we are involved.
                                </li>
                                <li>Cross-Border Disclosure of Information. We may disclose your personal information to
                                    international third parties, including countries outside the European Economic Area
                                    (EEA) (collectively "Cross-border Disclosure"), generally to arrange travel with a
                                    travel service provider on your behalf. Whenever we perform Cross-border
                                    Disclosures, we will do so in accordance with applicable law and ensure that a
                                    similar degree of protection is afforded to it by implementing appropriate
                                    safeguards. Cross-border Disclosures outside the EEA will only be made:
                                </li>
                                <li>To a country recognized by the European Commission as providing an adequate level of
                                    protection; or
                                </li>
                                <li>
                                    To a country which does not offer adequate protection, but whose transfer has been
                                    governed by the standard contractual clauses of the European Commission, or by
                                    implementing other appropriate cross-border transfer solutions to provide adequate
                                    protection.
                                </li>
                            </ul>
                            <li><strong>Cookies and Other Tracking Mechanisms</strong></li>
                            <p>We and our service providers use cookies and other tracking mechanisms to track your use
                                of our website or MiniApp services. We use these in a range of ways including:</p>
                            <ul>
                                <li>Keeping you signed in;</li>
                                <li>Understanding how you use our website;</li>
                                <li>Functionality - TonHotels uses tracking mechanisms so that we recognize you on our
                                    website and remember your previously selected preferences. These could include what
                                    language you prefer, the currency in which prices are displayed, and the location
                                    you are in. A mix of first-party and third-party tracking mechanisms are used; and
                                </li>
                                <li>Advertising - TonHotels uses these tracking mechanisms to collect information about
                                    your visit to our website, the content you viewed, the links you followed, and
                                    information about your browser, device, and your IP address. TonHotels shares some
                                    limited aspects of this personal information with third parties for advertising
                                    purposes. We also share personal information collected through tracking mechanisms
                                    with our advertising partners. This means that when you visit another website, you
                                    may be shown advertising based on your browsing patterns on our website.
                                </li>
                                <li>
                                    Types of tracking mechanism we use:
                                </li>
                                <li>
                                    Cookies. We or our service providers use cookies to track visitor activity on our
                                    website. A cookie is a text file that a website transfers to your computer's hard
                                    drive for record-keeping purposes. We or our service providers may use cookies to
                                    track user activities on our website, such as the pages visited and time spent on
                                    our website. Most browsers allow users to refuse cookies. The ‘Help’ portion of the
                                    toolbar on most browsers will tell you how to prevent your computer from accepting
                                    new cookies, how to have the browser notify you when you receive a new cookie, or
                                    how to disable cookies altogether. Users who disable cookies may not be able to
                                    browse certain areas of the website. Please refer to our Cookie Policy for further
                                    information regarding Cookies used on our website.
                                </li>
                            </ul>
                            <li><strong>Third-Party Analytics</strong></li>
                            <p>We use service providers, such as Google Analytics demographics and interests reports as
                                well as advertising reporting features, to evaluate the use of our website and our
                                services. We or our service providers use automated devices and applications to evaluate
                                the use of our website and services. We or our service providers use these tools to help
                                us improve our website, services, performance, and user experiences. These entities may
                                use cookies and other tracking technologies, such as web beacons or Flash LSO, to
                                perform their services. To opt out of Google Analytics, go here. To opt out of Google
                                Analytics for display advertising or customize Google display network ads, you can visit
                                the Google Ads Settings page.</p>
                            <li><strong>Your Rights</strong></li>
                            <p>We comply and oblige with the rights of any users in the European Economic Area or United
                                Kingdom under the European and United Kingdom General Data Protection Regulations (GDPR)
                                respectively and the rights of California residents under the California Consumer
                                Privacy Act (CCPA). Collectively, in compliance with the GDPR, CCPA, and other
                                applicable personal information protection laws, your rights to your personal
                                information are as listed below:</p>
                            <ul>
                                <li>The right to access – You have the right to request copies of your personal
                                    information from us. We may charge you a small fee to cover the cost of this
                                    request.
                                </li>
                                <li>The right to rectification – You have the right to request that we correct any
                                    information you believe is inaccurate. You also have the right to request us to
                                    complete the information you believe is incomplete.
                                </li>
                                <li>The right to erasure – You have the right to request that we erase your personal
                                    information, under certain conditions.
                                </li>
                                <li>The right to restrict processing – You have the right to request that we restrict
                                    the processing of your personal information, under certain conditions.
                                </li>
                                <li>The right to object to processing – You have the right to object to our processing
                                    of your personal information, under certain conditions.
                                </li>
                                <li>The right to personal information portability – You have the right to request that
                                    we transfer the personal information that we have collected to another organization,
                                    or directly to you, under certain conditions.
                                </li>
                                <li>
                                    The right to opt-out of the sale of personal information – As the terms are defined
                                    under the CCPA, we do not “sell” your “personal information.”
                                </li>
                            </ul>
                            <li><strong>Third-Party Payment Processors and Wallets</strong></li>
                            <p>
                                To use the services offered on our website, you may opt to use payment options that
                                utilize a third-party payment processor or a third-party wallet which allows you to
                                engage in transactions on public blockchains. Your interactions with any third-party
                                payment processor or wallet provider are governed by the applicable terms of service and
                                privacy policy of that third party.
                            </p>
                            <li><strong>Contact Us</strong></li>
                            <p>
                                If you have any questions or concerns about the privacy aspects of our website or
                                services or want to make a complaint about an interference with your privacy by
                                TonHotels, please email us at <a href="https://t.me/TonHotels_support_bot"
                                                                 target="_blank">
                                https://t.me/TonHotels_support_bot
                            </a> or <a href="mailto:team@e-residence.com">team@e-residence.com</a>. We will do our best
                                to resolve your complaint as quickly as
                                possible. You can also contact your local data protection authority to lodge a
                                complaint. If you are in the EU, you can find your Data Protection Authority <a
                                href="https://www.edpb.europa.eu/about-edpb/about-edpb/members_en" target="_blank">
                                here
                            </a>. If you are in the UK, you can contact the Information Commissioner’s Office here.
                            </p>
                            <li><strong>Changes to This Privacy Policy</strong></li>
                            <p>
                                We may change this privacy policy from time to time, and new versions will be posted on
                                this website, so please check back periodically for updates. If you are registered with
                                us, we will let you know when we update this privacy policy.
                            </p>
                            <p><br/></p>
                            <p><br/></p>
                        </ol>
                    </>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;

