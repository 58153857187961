import { create } from 'zustand'
import hotelApi from "../api/hotel";
import { Hotel, Review, Room } from "entitites";
import useSearchStore from "./searchState";
import { getQueryParams } from '../utils/getUrlQueryParams';
import { CityState } from "../entitites";
import { subscribeWithSelector } from "zustand/middleware";
import { isDateInThePast } from "utils/date";


interface HotelState {
    hotel: Hotel | null;
    loadingRooms: boolean;
    loadingRoomsError: boolean;
    hotelRooms: { price: number, rooms: any } | null;
    selectedRoom: Room | null;
    reviews: {
        items: Review[];
        limit: number;
        offset: number;
        isLoadingReviews: boolean;
    };
    isLoadingHotel: boolean;
    error: any;
    getHotel: (data: any) => void;
    getHotelReviews: (data: GetHotelReviewsData) => void;
    getRooms: () => Promise<void>;
    setRoom: (selectedRoom: Room) => void;
    reset: () => void;
}

const initialState = {
    hotel: null,
    loadingRooms: false,
    loadingRoomsError: false,
    hotelRooms: null,
    selectedRoom: null,
    reviews: {
        items: [],
        limit: 5,
        offset: 0,
        isLoadingReviews: false,
    },
    isLoadingHotel: true,
    error: null,
}

interface GetHotelReviewsData {
    hotelId: string;
    limit: number;
    offset: number;
}


const useHotelStore = create<HotelState>()(subscribeWithSelector((set, get) => ({
    ...initialState,
    getHotel: async (data: any) => {
        set({isLoadingHotel: true});
        const searchState = useSearchStore.getState()
        set({loadingRooms: true});
        try {
            const [hotel, reviews] = await Promise.all([
                hotelApi.getHotel(data.hotelId),
                hotelApi.getReviews(data.hotelId, get().reviews.limit, get().reviews.offset),
                // hotelApi.getRoomPhotos(data.hotelId)
            ]);

            const allParams = getQueryParams();

            const startDate = allParams['startDate'] ? allParams['startDate'] : searchState.dates.startDate;
            const endDate = allParams['endDate'] ? allParams['endDate'] : searchState.dates.endDate;
            const adults = allParams['adults'] ? parseInt(allParams['adults']) : searchState.guests.adults;
            const children = allParams['children'] ? new Array(parseInt(allParams['children'])).fill(8)
                : new Array(searchState.guests.children + searchState.guests.infants).fill(8);
            const location = searchState.location;

            const offset = reviews.items.length
            set({
                hotel, reviews: {
                    ...get().reviews,
                    items: [...get().reviews.items, ...reviews.items],
                    offset: offset,
                }, isLoadingHotel: false,
            })
            hotelApi.getRooms({
                endDate: endDate,
                startDate: startDate,
                item_id: location,
                hotel: data.hotelId,
                adults: adults,
                children: children,
            }).then((response) => {
                set({
                    hotelRooms: response,
                    loadingRooms: false
                })
            }).catch(() => {
                set({loadingRooms: false, loadingRoomsError: true})
            })
        } catch (error) {
            set({error, isLoadingHotel: false})
        }
    },
    getHotelReviews: async (data) => {
        set((state) => ({
            reviews: {
                ...state.reviews,
                isLoadingReviews: true
            }
        }));
        try {
            if (data.hotelId) {
                const reviews = await hotelApi.getReviews(data.hotelId, data.limit, data.offset)
                const offset = [...get().reviews.items, ...reviews.items].length
                set((state) => ({
                    reviews: {
                        ...state.reviews,
                        items: [...state.reviews.items, ...reviews.items],
                        isLoadingReviews: false,
                        offset: offset,
                    }
                }));
            }
        } catch (error) {
            set((state) => ({
                error,
                reviews: {
                    ...state.reviews,
                    isLoadingReviews: false
                }
            }));
        }
    },
    getRooms: async () => {
        const searchState = useSearchStore.getState()
        try {
            set({
                loadingRooms: true,
                selectedRoom: null
            })
            const hotelId = get().hotel?.id
            if (hotelId) {
                const response = await hotelApi.getRooms({
                    endDate: searchState.dates.endDate,
                    startDate: searchState.dates.startDate,
                    item_id: searchState.location,
                    hotel: hotelId,
                    adults: searchState.guests.adults,
                    children: new Array(searchState.guests.children).fill(8),
                })
                set({
                    hotelRooms: response,
                    loadingRoomsError: false
                })
            }
        } catch (error) {
            set({error, loadingRoomsError: true})
        } finally {
            set({
                loadingRooms: false,
            })
        }
    },
    setRoom: (selectedRoom: Room) => {
        set(() => {
            return {
                selectedRoom
            }
        })
    },
    reset: () => {
        set(initialState)
    },
})),)

export default useHotelStore
